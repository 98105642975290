import { useChatRoom } from './index';
import { chatActions } from '../../lib/redux/chat/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function useSuggestedPrompt() {
  const dispatch = useDispatch();
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const chat = useSelector((state) => state.chat.data);
  const { generating, handleUserPromptFormSubmit } = useChatRoom();

  const handleSuggestedPromptSelection = (prompt) => {
    setSelectedPrompt(prompt);
    dispatch(chatActions.chatUpdated({ ...chat, prompt: prompt }));
  };

  useEffect(() => {
    if (chat.prompt && selectedPrompt === chat.prompt) {
      document.getElementById('user-prompt-submit-btn')?.click();
    }
  }, [chat.prompt, selectedPrompt]);

  useEffect(() => {
    dispatch(chatActions.chatUpdated({ ...chat, generating: generating }));
  }, [generating, dispatch]);

  return { handleSuggestedPromptSelection };
}
