import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PromptItem from '../../prompts/prompt-item';
import { useTriggers } from '../../../hooks/navigation';
import { userIsAdmin } from '../../../utils/shared';
import useMentorPrompts from '../../../hooks/api/mentors/useMentorPrompts';

export default function MentorPrompts() {
  const mentors = useSelector((state) => state.mentors);
  const { handleAddNewPromptBtnClick } = useTriggers();
  const { suggestedPrompts } = useMentorPrompts();

  const getPromptNameFromText = (text) => {
    const words = text?.split(' ');
    if (words?.length >= 6) {
      return `${words.slice(0, 10).join(' ')}...`;
    }
    return text;
  };

  const getPromptFromString = (text) => {
    return {
      prompt: getPromptNameFromText(text),
      description: text,
    };
  };

  return (
    <div className="w-layout-vflex edit-mentor-system-prompt-tab">
      <div className="w-layout-vflex prompt-category-container">
        <h5 className="prompt-category-container-title">System</h5>
        <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid">
          <PromptItem
            prompt={{
              ...getPromptFromString(mentors?.edit?.mentor?.system_prompt),
              isSystem: true,
            }}
          />
        </div>
      </div>
      <div className="w-layout-vflex prompt-category-container">
        <h5 className="prompt-category-container-title">Suggested Prompts</h5>
        <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid">
          {suggestedPrompts?.map((item, i) => (
            <PromptItem
              prompt={{ ...getPromptFromString(item), index: i }}
              key={`${i}-prompt`}
            />
          ))}
          <div
            className="w-layout-vflex prompt-grid-element add-prompt-grid-element light-grey-bg-dark-mode greyish-bg-in-darkmode add-prompt-grid-element-1"
            data-w-id="1dc72672-b432-9a64-835d-c12c25541aac"
            title={
              userIsAdmin()
                ? 'Add prompt'
                : 'This feature is available in our paid tier'
            }
            style={{
              padding: '10px',
              cursor: userIsAdmin() ? 'pointer' : 'not-allowed',
            }}
            onClick={userIsAdmin() ? handleAddNewPromptBtnClick : () => {}}
            id="w-node-_1dc72672-b432-9a64-835d-c12c25541aac-e5765214"
          >
            <div className="w-layout-hflex flex-block-18">
              <img
                alt=""
                className="image-28"
                loading="lazy"
                src="images/plus-4.svg"
              />
            </div>
            <h5 className="add-new-prompt-label">Add New Prompt</h5>
          </div>
        </div>
      </div>
    </div>
  );
}
