export default function GeneratingResponse() {
  return (
    <div className="ai-response-container ai-prompt-response-container light-black-bg-dark-mode learn-more-container">
      <div className="w-layout-hflex ai-response-container-content">
        <div className="ai-gif-container">
          <img
            src="/images/ai-academy-p-500.png"
            loading="lazy"
            alt=""
            className="image-3"
          />
        </div>
        <div
          className="ai-response-text-container whiten-in-dark-mode generating-result"
          style={{ fontSize: '15px' }}
        ></div>
      </div>
    </div>
  );
}
