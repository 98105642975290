import { userIsAdmin } from '../../../utils/shared';

export default function DocumentUpload({
  handlePromptFileUploadBtnClick,
  handleFileChange,
}) {
  return (
    <div
      data-w-id="712e5e43-6827-f046-875a-8d254cd1b749"
      className="has-custom-tooltip user-input-actions-container upload-file-input-action-container greyish-bg-in-darkmode all-whiten-img-on-hover-in-dark-mode"
      prop-events-names="onClick"
      style={{
        cursor: userIsAdmin() ? 'pointer' : 'not-allowed',
      }}
      onClick={userIsAdmin ? handlePromptFileUploadBtnClick : () => {}}
      prop-events-value-onclick="handlePromptFileUploadBtnClick"
    >
      <div className="tooltip tooltip-user-file-upload">
        Add Files (5 max, 10Mb each) Accepts pdf, txt, csv, etc.
      </div>
      <img
        src="images/paperclip.svg"
        loading="lazy"
        alt=""
        className="image-6"
      />
      <form id="attachment-form" action="#" style={{ display: 'none' }}>
        <input
          name="file"
          onChange={handleFileChange}
          id="attachment-upload"
          className="attachment-upload"
          type="file"
        />
      </form>
    </div>
  );
}
