import { useState, useEffect } from 'react';
import { handleStopBrowserStream } from '../../../utils/shared';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTriggers } from '../../../hooks/navigation';

import { DEFAULT_PARAMETERS_CONFIGURATION } from '../../../utils/audio/audioDetectionConfig';

import { createAudioMeter } from '../../../utils/audio/volume-meter';

let callRecorder = null;
let mediaStreamSource = null;
let audioContext = null;
let speechInitiated = false;
let initialSpeechTime = null;

let localStream;
// for video streaming
let peerConnection;
let remoteVideo;
let serverConnection;
let remoteAudio;
let uuid;
let speechEventList = [];
let isConnecting = false;

let ws_url = process.env.REACT_APP_IBL_WEB_RTC_WEBSOCKET_ORIGIN;

const peerConnectionConfig = {
  iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
};

export default function WebRTCChat() {
  const chat = useSelector((state) => state.chat.data);
  const sessionId = useSelector((state) => state.chat.sessionId);

  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);

  const [mentorIsSpeaking, setMentorIsSpeaking] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading] = useState(false);
  const [ready, setReady] = useState(false);
  const [connecting, setConnecting] = useState(false);

  let profileImageUrl = mentors?.mentor?.settings?.profile_image;
  if (profileImageUrl && !profileImageUrl.includes('s3.amazonaws.com')) {
    profileImageUrl += `?username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`;
  }

  const { _handleUpdateModalStatus } = useTriggers();

  const websocketData = {
    flow: {
      name: mentors?.mentor?.name,
      tenant: auth?.tenant?.key,
      username: auth?.user?.user_nicename,
      pathway: mentors?.mentor?.name,
    },
    session_id: sessionId,
    token: localStorage.getItem('axd_token'),
  };

  const handlePlayResponseStart = () => {
    console.log('now speaking');
    setMentorIsSpeaking(true);
  };

  const handlePlayResponseEnd = () => {
    setMentorIsSpeaking(false);
  };

  const handleEndCall = () => {
    handleStopBrowserStream();
    stop_recording();
    try {
      serverConnection?.removeAll();
    } catch (e) {
      console.log(e);
    }
    serverConnection.close();
    _handleUpdateModalStatus('ai-audio-chat-modal', false);
  };

  const handleOpenWebsocketConnection = (stream) => {
    if (isConnecting) {
      return;
    }
    isConnecting = true;
    uuid = createUUID();
    serverConnection = new WebSocket(ws_url);
    serverConnection.onmessage = gotMessageFromServer;
    serverConnection.onopen = () => {
      serverConnection.send(JSON.stringify(websocketData));
      handleVoiceRecord(stream);
    };
  };

  function start(isCaller = true) {
    peerConnection = new RTCPeerConnection(peerConnectionConfig);
    peerConnection.onicecandidate = gotIceCandidate;

    peerConnection.ontrack = gotRemoteStream;

    for (const track of localStream.getTracks()) {
      peerConnection.addTrack(track, localStream);
    }

    if (isCaller) {
      peerConnection.createOffer().then(createdDescription).catch(errorHandler);
    }
  }

  function gotMessageFromServer(message) {
    console.log('got message from server');
    if (!peerConnection) start(false);

    const signal = JSON.parse(message.data);

    // Ignore messages from ourself
    if (signal.uuid === uuid) return;

    if (signal.sdp) {
      peerConnection
        .setRemoteDescription(new RTCSessionDescription(signal))
        .then(() => {
          // Only create answers in response to offers
          if (signal.type === 'answer') {
            peerConnection.setRemoteDescription(
              new RTCSessionDescription(signal)
            );
            return;
          } else if (signal.type === 'offer') {
            peerConnection
              .createAnswer()
              .then(createdDescription)
              .catch(errorHandler);
          }
        })
        .catch(errorHandler);
    } else if (signal.ice) {
      peerConnection
        .addIceCandidate(new RTCIceCandidate(signal))
        .catch(errorHandler);
    }
  }

  function gotIceCandidate(event) {
    if (event.candidate != null) {
      let candidate = event.candidate;
      let data = JSON.stringify({
        ice: {
          address: candidate.address,
          candidate: candidate.candidate,
          component: candidate.component,
          foundation: candidate.foundation,
          port: candidate.port,
          priority: candidate.priority,
          protocol: candidate.protocol,
          relatedAddress: candidate.relatedAddress,
          relatedPort: candidate.relatedPort,
          sdpMLineIndex: candidate.sdpMLineIndex,
          sdpMid: candidate.sdpMid,
          tcpType: candidate.tcpType,
          type: candidate.type,
          usernameFragment: candidate.usernameFragment,
        },

        uuid: uuid,
      });
      serverConnection.send(data);
    }
  }

  function createdDescription(description) {
    peerConnection
      .setLocalDescription(description)
      .then(() => {
        serverConnection.send(
          JSON.stringify({ sdp: peerConnection.localDescription, uuid: uuid })
        );
      })
      .catch(errorHandler);
  }

  function gotRemoteStream(event) {
    if (event.track.kind === 'audio') {
      remoteAudio.srcObject = event.streams[0];
      remoteAudio.onloadedmetadata = () => {
        remoteAudio.play();
      };
    } else {
      remoteVideo.srcObject = event.streams[0];
      remoteVideo.onloadedmetadata = () => {
        remoteVideo.play();
      };
    }
  }

  function errorHandler(error) {
    console.log(error);
  }

  function createUUID() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
  }

  function start_recording() {
    serverConnection.send(
      JSON.stringify({
        type: 'start_record',
      })
    );
  }
  function stop_recording() {
    serverConnection.send(
      JSON.stringify({
        type: 'stop_record',
      })
    );
  }

  const handleSpeechStart = (event) => {
    if (!mentorIsSpeaking) {
      initialSpeechTime = new Date().getTime();
      callRecorder.start();
    }
  };

  const handleSilence = (event) => {
    const now = new Date().getTime();
    if (initialSpeechTime && now - initialSpeechTime > 3500) {
      callRecorder.stop();
    }
  };

  useEffect(() => {
    if (mentorIsSpeaking) {
      document.removeEventListener('speechstart', handleSpeechStart);
      speechEventList = speechEventList.filter(
        (item) => item !== 'speechstart'
      );

      document.removeEventListener('silence', handleSilence);
      speechEventList = speechEventList.filter((item) => item !== 'silence');
    } else {
      handleCallInProgress();
    }
  }, [mentorIsSpeaking]);

  const handleCallInProgress = () => {
    if (!speechEventList?.includes('speechstart')) {
      document.addEventListener('speechstart', handleSpeechStart);
      speechEventList?.push('speechstart');
    }
    if (!speechEventList?.includes('silence')) {
      document.addEventListener('silence', handleSilence);
      speechEventList?.push('speechstart');
    }
  };

  const handleRecordingStarts = () => {
    setIsRecording(true);
  };

  const handleRecordingStops = () => {
    setIsRecording(false);
  };

  const sendVoiceData = (blob) => {
    if (!mentorIsSpeaking) {
      serverConnection.send(blob);
    }
  };

  const handleVoiceRecord = async (stream) => {
    mediaStreamSource = audioContext.createMediaStreamSource(stream);

    // Create a new volume meter and connect it.
    window.meter = createAudioMeter(audioContext);
    mediaStreamSource.connect(window.meter);

    window.audioDetection(DEFAULT_PARAMETERS_CONFIGURATION);

    callRecorder = new MediaRecorder(stream);

    callRecorder.onstop = handleRecordingStops;
    callRecorder.onstart = handleRecordingStarts;

    callRecorder.ondataavailable = (e) => {
      let blob = new Blob([e.data], { type: 'audio/mpeg-3' });
      sendVoiceData(blob);
    };
    start_recording();
    handleCallInProgress();
    setReady(true);
  };

  useEffect(() => {
    if (ready) {
      remoteAudio = document.getElementById('ai-audio-response');
    }
  }, [ready]);

  useEffect(() => {
    window.AudioContext = window.AudioContext || window?.webkitAudioContext;
    // grab an audio context
    audioContext = new AudioContext();

    try {
      // ask for an audio input
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            mandatory: {
              googEchoCancellation: 'false',
              googAutoGainControl: 'false',
              googNoiseSuppression: 'false',
              googHighpassFilter: 'false',
            },
            optional: [],
          },
        })
        .then((stream) => {
          window.audioStreamReference = stream;
          localStream = stream;
          handleOpenWebsocketConnection(stream);
        })
        .catch((error) => {
          console.log(error, 'error accessing media');
        });
    } catch (e) {
      toast.error('getUserMedia threw exception :' + e);
    }
  }, []);

  return (
    <div
      className="modal ai-audio-chat-modal lighter-dark-bg"
      style={{ display: 'flex' }}
    >
      <audio
        onEnded={handlePlayResponseEnd}
        onPlay={handlePlayResponseStart}
        style={{ display: 'none' }}
        id="ai-audio-response"
        autoPlay
        controls
      ></audio>
      {ready && (
        <div className="w-layout-vflex modal-container ai-audio-chat-modal-container">
          <div className="w-layout-vflex modal-body ai-audio-chat-modal-body">
            <div className="w-layout-vflex flex-block-74">
              <h4 className="audio-chat-welcome-text">
                {websocketData?.flow?.name}
              </h4>
              <div className="text-block-48" id="call-duration"></div>
            </div>
            <div className="w-layout-hflex audio-chat-mentor-img-container">
              <img
                src={profileImageUrl ?? '/images/1.jpg'}
                loading="lazy"
                alt=""
                is-speaking={`${mentorIsSpeaking ? '1' : ''}`}
                className="audio-chat-mentor-image"
              />
              <div
                className={`audio-circle-wave-container w-embed ${mentorIsSpeaking ? '' : 'hidden'}`}
              >
                <div className="circle delay1"></div>
                <div className="circle delay2"></div>
                <div className="circle delay3"></div>
                <div className="circle delay4"></div>
              </div>
            </div>
            <div className="w-layout-hflex audio-chat-animated-soundwave-container">
              <canvas
                style={{ backgroundColor: 'transparent' }}
                id="ai-voice-visualizer"
              ></canvas>
            </div>
            <div className="w-layout-hflex audio-chat-action-btn-container">
              <div className="w-layout-hflex audio-chat-action-btn audio-chat-animated-icon">
                <img
                  src="images/phone-hangup-svgrepo-com.svg"
                  loading="lazy"
                  alt=""
                  className="audio-chat-action-icon"
                />
                <img
                  src="images/Siri-Logo-Transparent-Free-PNG.png"
                  loading="lazy"
                  sizes="100vw"
                  srcSet="images/Siri-Logo-Transparent-Free-PNG-p-500.png 500w, images/Siri-Logo-Transparent-Free-PNG-p-800.png 800w, images/Siri-Logo-Transparent-Free-PNG.png 820w"
                  alt=""
                  className="audio-chat-mentor-img"
                />
              </div>
              <div className="w-layout-hflex audio-chat-action-btn audio-chat-record-stop-action-btn">
                <div className="w-layout-hflex audio-chat-action-btn-icon-container">
                  <img
                    src="images/stop-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className={`audio-chat-action-icon display-block smaller stop-btn ${isRecording ? '' : 'hidden'}`}
                  />
                  <img
                    src="images/833-1.gif"
                    loading="lazy"
                    alt=""
                    className={`audio-chat-action-icon display-block no-invert loading-btn ${isUploading ? '' : 'hidden'}`}
                  />
                  <img
                    src="images/microphone-svgrepo-com-1.svg"
                    loading="lazy"
                    alt=""
                    className={`audio-chat-action-icon display-block smaller record-btn ${isRecording || isUploading ? 'hidden' : ''}`}
                  />
                </div>
                <div
                  className={`audio-circle-wave-container smaller w-embed ${isRecording ? '' : 'hidden'}`}
                >
                  <div className="circle smaller delay1"></div>
                  <div className="circle smaller delay2"></div>
                  <div className="circle smaller delay3"></div>
                  <div className="circle smaller delay4"></div>
                </div>
              </div>
              <div
                onClick={handleEndCall}
                data-w-id="2df25bcb-62e3-8af3-f355-4c4807cd9b1b"
                className="w-layout-hflex audio-chat-action-btn audio-chat-hang-action-btn"
              >
                <img
                  src="images/phone-hangup-svgrepo-com.svg"
                  loading="lazy"
                  alt=""
                  className="audio-chat-action-icon display-block"
                />
              </div>
            </div>
            <div className="html-embed w-embed">
              <ul className="audio-wave">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {!ready && (
        <div
          className="w-layout-vflex modal-container ai-audio-chat-modal-container"
          style={{ justifyContent: 'center' }}
        >
          <div
            style={{
              color: 'white',
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            Connecting...
          </div>
        </div>
      )}
    </div>
  );
}
