import { getFileExtension } from '../../../utils/shared';

export default function DocumentUploadProgress({ file }) {
  return (
    <div className="w-layout-hflex file-uploading-block">
      <div className="w-layout-hflex single-file-uploading-box">
        <div className="w-layout-hflex file-upload-status-icon-block">
          <img
            src="images/833-1.gif"
            loading="lazy"
            alt=""
            className="file-upload-status-icon"
          />
          <img
            src="images/check.svg"
            loading="lazy"
            alt=""
            className="file-upload-status-icon success"
          />
        </div>
        <div className="w-layout-vflex file-upload-info-block">
          <h6 className="file-upload-name-block">{file?.name}</h6>
          <div className="file-upload-extension-block">
            {getFileExtension(file?.name)}
          </div>
        </div>
        <div className="w-layout-vflex file-upload-closer-block">
          <img
            src="images/x-circle.svg"
            loading="lazy"
            alt=""
            className="file-upload-closer"
          />
        </div>
      </div>
    </div>
  );
}
