import React from "react";
import "./Mobilesettingsmenudropdownbox.css";


const Mobilesettingsmenudropdownbox = props => {
    
    return (
<nav className="header-settings-dropdown-container settings-dropdown mobile-settings-dropdown w-dropdown-list">
<div className="w-layout-vflex user-menu-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode whiten-border-in-dark-mode user-menu-settings-dropdown">
<a className="user-menu-link greyish-bg-in-darkmode theme-switcher-container user-menu-dark-mode w-inline-block" data-w-id="8c9dc8cc-ae1c-10a5-0d3c-4984a189d1da" href="#"><img alt="" className="user-menu-icon" loading="lazy" src="images/moon-svgrepo-com.svg"/>
<div className="user-menu-label">Use Dark Theme</div>
</a>
<a className="user-menu-link greyish-bg-in-darkmode theme-switcher-container user-menu-light-mode w-inline-block" data-w-id="8c9dc8cc-ae1c-10a5-0d3c-4984a189d1de" href="#"><img alt="" className="user-menu-icon" loading="lazy" src="images/sun-1.svg"/>
<div className="user-menu-label">Use Light Theme</div>
</a>
<div className="w-layout-hflex switcher-container switcher-mobile-container">
<h5 className="switch-label-txt whiten-in-dark-mode">LEARNER</h5>
<div className="switch-html-embed w-embed"><label className="switch learner-instructor-switch">
<input defaultChecked="" prop-events-value-onchange="handleUserTypeSwitchChange" type="checkbox"/>
<span className="slider round">
<img alt="" className="left-img" src="/custom-assets/img/graduation-cap.svg"/>
<img alt="" className="right-img" src="/custom-assets/img/presentation.svg"/>
</span>
</label>
<style dangerouslySetInnerHTML={{__html: `
    .af-view .switch {
        position: relative;
        display: inline-block;
        width: 46px;
        height: 20px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The slider */
    .af-view .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .slider:before {
        position: absolute;
        content: \"\";
        height: 24px;
        width: 24px;
        left: 0px;
        bottom: -2px;
        background-color: #000000;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view input:not(:checked) + .slider img.right-img{
        display: none;
    }
    .af-view input:checked + .slider img.left-img{
        display: none;
    }
    .af-view input:checked + .slider:before{
        background-color: #2467eb;
    }
    .af-view .slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .slider img.left-img{
        left: 3px;
    		bottom: 0px;
    }
    .af-view .slider img.right-img{
        right: 0px;
    		bottom: -1px;
        width:16px;
    }
    .af-view input:checked + .slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view input:focus + .slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded sliders */
    .af-view .slider.round {
        border-radius: 15px;
    }
    .af-view .slider.round:before {
        border-radius: 50%;
    }
    .af-view .input-checked-label{
    		color:rgb(36, 103, 235)!important;
    }
 ` }} />
</div>
<h5 className="switch-label-txt whiten-in-dark-mode instructor-label">INSTRUCTOR</h5>
</div>
<a className="user-menu-link greyish-bg-in-darkmode user-menu-llm-link w-inline-block" data-w-id="8c9dc8cc-ae1c-10a5-0d3c-4984a189d1e8" href="#" prop-events-value-onclick="handleLLMProviderMenuBtnClick"><img alt="" className="user-menu-icon" loading="lazy" src="images/cpu-1.svg"/>
<div className="user-menu-label">LLM Providers</div>
</a>
<a className="user-menu-link greyish-bg-in-darkmode user-menu-datasets-link w-inline-block" data-w-id="8c9dc8cc-ae1c-10a5-0d3c-4984a189d1ec" href="#"><img alt="" className="user-menu-icon" loading="lazy" src="images/notepad-1-svgrepo-com.svg"/>
<div className="user-menu-label">Datasets</div>
</a>
<a className="user-menu-link greyish-bg-in-darkmode w-inline-block" data-w-id="8c9dc8cc-ae1c-10a5-0d3c-4984a189d1f0" href="#" prop-events-value-onclick="handleMentorsMenuBtnClick"><img alt="" className="user-menu-icon" loading="lazy" src="images/teacher-svgrepo-com-2.svg"/>
<div className="user-menu-label">Mentors</div>
</a>
<a className="user-menu-link greyish-bg-in-darkmode user-menu-users-link w-inline-block" data-w-id="8c9dc8cc-ae1c-10a5-0d3c-4984a189d1f4" href="#" prop-events-value-onclick="handleUsersMenuBtnClick"><img alt="" className="user-menu-icon" loading="lazy" src="images/users-1.svg"/>
<div className="user-menu-label">Users</div>
</a>
</div>
</nav>
)}

export default Mobilesettingsmenudropdownbox;