import React from "react";
import "./Aiaudiochat.css";


const Aiaudiochat = props => {
    
    return (
<div className="modal ai-audio-chat-modal lighter-dark-bg modal-not-closable-externally">
<div className="w-layout-vflex modal-container ai-audio-chat-modal-container">
<div className="w-layout-vflex modal-body ai-audio-chat-modal-body">
<div className="w-layout-vflex flex-block-74">
<h4 className="audio-chat-welcome-text">{props.mentor}</h4>
<div className="text-block-48">{props.counter}</div>
</div>
<div className="w-layout-hflex audio-chat-mentor-img-container"><img alt="" className="audio-chat-mentor-image" loading="lazy" src="images/2.jpg"/>
<div className="audio-circle-wave-container hidden w-embed">
<style dangerouslySetInnerHTML={{__html: `
	.af-view .delay1 {
    animation: waves 1.5s linear infinite;
    animation-delay: .1s;
  }
  .af-view .delay2 {
    animation: waves 1.5s linear .3s forwards infinite,
  }
  .af-view .delay3 {
    animation: waves 1.5s linear .7s forwards infinite;
  }
  .af-view .delay4 {
    animation: waves 1.5s linear 1s forwards infinite;
  }
.af-view .circle {
  display: block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: rgba(255,255,255,.80);
  margin: 10px;
  transition: 5s ease;
  position: absolute;
  top: 0px
}
@-webkit-keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
 ` }} />
<div className="circle delay1"></div>
<div className="circle delay2"></div>
<div className="circle delay3"></div>
<div className="circle delay4"></div>
</div>
</div>
<div className="w-layout-hflex audio-chat-animated-soundwave-container"><img alt="" className="audio-chat-animated-soundwave-img" loading="lazy" sizes="100vw" src="images/colorful-sound-wave-equalizer-2.png" srcSet="images/colorful-sound-wave-equalizer-2-p-500.png 500w, images/colorful-sound-wave-equalizer-2-p-800.png 800w, images/colorful-sound-wave-equalizer-2-p-1080.png 1080w, images/colorful-sound-wave-equalizer-2-p-1600.png 1600w, images/colorful-sound-wave-equalizer-2-p-2000.png 2000w, images/colorful-sound-wave-equalizer-2.png 2500w"/></div>
<div className="w-layout-hflex audio-chat-action-btn-container">
<div className="w-layout-hflex audio-chat-action-btn audio-chat-animated-icon"><img alt="" className="audio-chat-action-icon" loading="lazy" src="images/phone-hangup-svgrepo-com.svg"/><img alt="" className="audio-chat-mentor-img" loading="lazy" sizes="100vw" src="images/Siri-Logo-Transparent-Free-PNG.png" srcSet="images/Siri-Logo-Transparent-Free-PNG-p-500.png 500w, images/Siri-Logo-Transparent-Free-PNG-p-800.png 800w, images/Siri-Logo-Transparent-Free-PNG.png 820w"/></div>
<div className="w-layout-hflex audio-chat-action-btn audio-chat-record-stop-action-btn" prop-events-value-onclick="handleRecordBtnClick">
<div className="w-layout-hflex audio-chat-action-btn-icon-container"><img alt="" className="audio-chat-action-icon display-block smaller hidden stop-btn" loading="lazy" src="images/stop-svgrepo-com.svg"/><img alt="" className="audio-chat-action-icon display-block no-invert hidden loading-btn" loading="lazy" src="images/833-1.gif"/><img alt="" className="audio-chat-action-icon display-block smaller record-btn" loading="lazy" src="images/microphone-svgrepo-com-1.svg"/></div>
<div className="audio-circle-wave-container smaller hidden w-embed">
<style dangerouslySetInnerHTML={{__html: `
    .af-view .smaller.delay1 {
        animation: waves 1.5s linear infinite;
        animation-delay: .1s;
    }
    .af-view .smaller.delay2 {
        animation: waves 1.5s linear .3s forwards infinite,
    }
    .af-view .smaller.delay3 {
        animation: waves 1.5s linear .7s forwards infinite;
    }
    .af-view .smaller.delay4 {
        animation: waves 1.5s linear 1s forwards infinite;
    }
    .af-view .smaller.circle {
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: rgba(255,255,255,.80);
        margin: 10px;
        transition: 5s ease;
        position: absolute;
        top: 0px
    }
    @-webkit-keyframes waves {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(4);
            opacity: 0;
        }
    }
 ` }} />
<div className="circle smaller delay1"></div>
<div className="circle smaller delay2"></div>
<div className="circle smaller delay3"></div>
<div className="circle smaller delay4"></div>
</div>
</div>
<div className="w-layout-hflex audio-chat-action-btn audio-chat-hang-action-btn" data-w-id="2df25bcb-62e3-8af3-f355-4c4807cd9b1b" prop-events-value-onclick="handleHangBtnClick"><img alt="" className="audio-chat-action-icon display-block" loading="lazy" src="images/phone-hangup-svgrepo-com.svg"/></div>
</div>
<div className="html-embed w-embed">
<ul className="audio-wave">
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
<li></li>
</ul>
<style dangerouslySetInnerHTML={{__html: `
.af-view ul.audio-wave{
    background: rgba(0, 0, 0, 0.8);
    width:200px;
    height:45px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:0;
    margin:0;
    transition:ease 0.2s;
    position:relative;
}
.af-view ul.audio-wave li{
    list-style:none;
    height:20px;
    width:4px;
    border-radius:10px;
    background: #545FE5;
    margin:0 3px;
    padding:0;
    animation-name: wave4;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition:ease 0.2s;
}
.af-view ul.audio-wave li:nth-child(2) {
    animation-name: wave2;
    animation-delay:0.2s;
}
.af-view ul.audio-wave li:nth-child(3) {
    animation-name: wave3;
    animation-delay:0.23s;
    animation-duration: 0.4s;
}
.af-view ul.audio-wave li:nth-child(4) {
    animation-name: wave4;
    animation-delay:0.1s;
    animation-duration: 0.3s;
}
.af-view ul.audio-wave li:nth-child(5) {
    animation-delay:0.5s;
}
.af-view ul.audio-wave li:nth-child(6) {
    animation-name: wave2;
    animation-duration: 0.5s;
}
.af-view ul.audio-wave li:nth-child(8) {
    animation-name: wave4;
    animation-delay:0.4s;
    animation-duration: 0.25s;
}
.af-view ul.audio-wave li:nth-child(9) {
    animation-name: wave3;
    animation-delay:0.15s;
}
.af-view ul.audio-wave li:nth-child(10) {
    animation-delay:0.5s;
}
.af-view ul.audio-wave li:nth-child(11) {
    animation-name: wave2;
    animation-duration: 0.5s;
}
.af-view ul.audio-wave li:nth-child(12) {
    animation-name: wave3;
    animation-delay:0.4s;
    animation-duration: 0.25s;
}
.af-view ul.audio-wave li:nth-child(13) {
    animation-name: wave4;
    animation-delay:0.15s;
}
.af-view ul.audio-wave li:nth-child(14) {
    animation-name: wave4;
    animation-duration: 0.5s;
}
.af-view ul.audio-wave li:nth-child(15) {
    animation-name: wave4;
    animation-delay:0.1s;
    animation-duration: 0.5s;
}
@keyframes wave1 {
    .af-view from {transform:scaleY(1);}
    .af-view to {transform:scaleY(0.5);}
}
@keyframes wave2 {
    .af-view from {transform:scaleY(0.3);}
    .af-view to {transform:scaleY(0.6);}
}
@keyframes wave3 {
    .af-view from {transform:scaleY(0.6);}
    .af-view to {transform:scaleY(0.8);}
}
@keyframes wave4 {
    .af-view from {transform:scaleY(0.2);}
    .af-view to {transform:scaleY(0.5);}
}
 ` }} />
</div>
</div>
</div>
</div>
)}

export default Aiaudiochat;