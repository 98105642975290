import { useDispatch, useSelector } from 'react-redux';
import useAxdApi from '../api/base/useAxdApi';
import { useEffect } from 'react';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { userIsAdmin } from '../../utils/shared';
import demoLLMData from '../../demos/llms.json';

export default function useLLMs() {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const dispatch = useDispatch();
  const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-llms/`;

  const { loading, data, error } = useAxdApi(
    endpoint,
    'GET',
    {},
    false,
    !mentors?.llms
  );

  const parseToModels = (data) => {
    const models = [];
    data?.forEach((item) => {
      item?.chat_models?.forEach((model) => {
        models.push({
          ...item,
          model: model.llm_name,
          description: model.description,
        });
      });
    });

    return models;
  };

  useEffect(() => {
    if (!userIsAdmin()) {
      dispatch(mentorActions.llmsUpdated(demoLLMData));
    }
  }, []);

  useEffect(() => {
    if (userIsAdmin() && data) {
      dispatch(mentorActions.llmsUpdated(parseToModels(data)));
    }
  }, [data, dispatch]);

  return { loading, error };
}
