"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerUser = exports.submitRegistrationForm = void 0;
const config_1 = require("../config");
const http_1 = require("../http");
const validation = require("../ibl-web/validation");
const http = new http_1.default();
function validateRegistrationForm(callback = null) {
    const inputs = document.querySelectorAll('input[form-type="signup"]');
    let isValidForm = true;
    let password = '';
    let confrimPassword = '';
    let passwordElement = null;
    let emailElement = null;
    let email = '';
    let usernameElement = null;
    let username = '';
    let errorMsg;
    for (let input of Array.from(inputs)) {
        const inputValue = input.value;
        const inputName = input.getAttribute('name');
        let is_company_optional = true;
        if (inputName == 'company') {
            is_company_optional = !!input.getAttribute('data-required');
        }
        if (inputName != 'company' || !is_company_optional) {
            if (!inputValue) {
                let label;
                if (inputName == 'password') {
                    label = 'Password';
                }
                if (inputName == 'email') {
                    label = 'Email';
                }
                if (inputName == 'confirm-password') {
                    label = 'Confirm Password';
                }
                if (inputName == 'name') {
                    label = 'Full Name';
                }
                if (inputName == 'username') {
                    label = 'Public Username';
                }
                errorMsg = `${label} is required.`;
                if (callback) {
                    callback(errorMsg, input);
                }
                else {
                    validation.renderSpanElement(errorMsg, input);
                }
                isValidForm = false;
            }
            else {
                if (['password', 'confirm-password'].includes(inputName)) {
                    if (inputName == 'password') {
                        password = inputValue;
                        passwordElement = input;
                    }
                    else {
                        confrimPassword = inputValue;
                    }
                }
                if (inputName == 'email') {
                    email = inputValue;
                    emailElement = input;
                }
                if (inputName == 'username') {
                    username = inputValue;
                    usernameElement = input;
                }
                if (!['password', 'email'].includes(inputName)) {
                    if (callback) {
                        callback(null, input);
                    }
                    else {
                        validation.renderSpanElement(null, input);
                    }
                }
            }
        }
    }
    if (email) {
        errorMsg = validation.validateEmail(email);
        if (callback) {
            callback(errorMsg, emailElement);
        }
        else {
            validation.renderSpanElement(errorMsg, emailElement);
        }
        isValidForm = errorMsg ? false : isValidForm;
    }
    if (username) {
        errorMsg = validation.validateUsername(username);
        if (callback) {
            callback(errorMsg, usernameElement);
        }
        else {
            validation.renderSpanElement(errorMsg, usernameElement);
        }
        isValidForm = errorMsg ? false : isValidForm;
    }
    if (password) {
        errorMsg = validation.validatePassword(password);
        if (callback) {
            callback(errorMsg, passwordElement);
        }
        else {
            validation.renderSpanElement(errorMsg, passwordElement);
        }
        isValidForm = errorMsg ? false : isValidForm;
    }
    const errorDiv = document.querySelector('#auth-error');
    if (password != confrimPassword) {
        isValidForm = false;
        errorDiv.innerHTML = 'Passwords must match';
        errorDiv.style.display = 'block';
    }
    else {
        errorDiv.style.display = 'none';
    }
    return isValidForm;
}
function submitRegistrationForm() {
    const isValidForm = validateRegistrationForm();
    if (isValidForm === true) {
        // initiateRegistration();
    }
}
exports.submitRegistrationForm = submitRegistrationForm;
function registerUser(data, successCb, errorCb) {
    http.post(config_1.default.IBL_EDX.REGISTER_USER, data, successCb, errorCb, false, 'IBL_LMS_URL');
}
exports.registerUser = registerUser;
