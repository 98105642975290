"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkoutSession = void 0;
const config_1 = require("../config");
const http_1 = require("../http");
const http = new http_1.default();
function checkoutSession(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_DM.CREATE_CHECKOUT_SESSION(data.org, data.username), JSON.stringify(data), successCb, errorCb, true, 'IBL_AXD_URL');
}
exports.checkoutSession = checkoutSession;
