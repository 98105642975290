import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { useTriggers } from '../../navigation';

export default function useMentorEmbed(mentor) {
  const [mentorToEmbed, setMentorToEmbed] = useState(mentor);
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { _handleUpdateModalStatus } = useTriggers();

  const [settings, setSettings] = useState({
    initial_message: '',
    suggested_message: '',
    metadata: {
      primary_color: '#2467eb',
      secondary_color: '#000',
    },
    allow_anonymous: true,
  });

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (mentor) {
      const data = mentor.settings;
      const dataToOverride = {
        initial_message: data?.initial_message ?? '',
        suggested_message: data?.suggested_message ?? '',
        metadata: {
          ...settings.metadata,
          ...(data?.metadata ?? {}),
        },
        allow_anonymous: Boolean(data?.allow_anonymous),
      };
      setSettings(dataToOverride);
    }
  }, [mentor]);

  const handleSubmit = async () => {
    setSubmitting(true);
    const payload = {
      metadata: settings.metadata,
      allow_anonymous: settings?.allow_anonymous,
    };
    if (settings.initial_message) {
      payload['initial_message'] = settings.initial_message;
    }

    if (settings.suggested_message) {
      payload['suggested_message'] = settings.suggested_message;
    }

    if (mentor.custom_css) {
      payload['custom_css'] = mentor.custom_css;
    }
    await handleUpdateSettings(mentor, payload);
    setMentorToEmbed({
      ...mentor,
      allow_anonymous: settings?.allow_anonymous,
    });
    setSubmitting(false);
    _handleUpdateModalStatus('embed-mentor-modal', true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleMetadataChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => {
      return {
        ...prevState,
        metadata: { ...prevState.metadata, [name]: value },
      };
    });
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setSettings((prevState) => {
      return { ...prevState, [name]: checked };
    });
  };

  const handleUpdatePreview = () => {
    const iframedSite = document.querySelector('#embed-mentor-preview');
    const message = JSON.stringify({
      welcomeMessage: settings?.initial_message,
      defaultPrompt: settings?.suggested_message,
      css: settings?.custom_css,
      allowAnonymous: settings?.allow_anonymous,
    });
    iframedSite.contentWindow.postMessage(message, '*');
  };

  useEffect(() => {
    handleUpdatePreview();
  }, [settings]);

  return {
    handleChange,
    handleCheckChange,
    handleMetadataChange,
    handleSubmit,
    mentorToEmbed,
    submitting,
    settings,
  };
}
