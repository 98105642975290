import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { authActions } from '../../lib/redux/auth/slice';
import useAnonymousMode from '../iframe/useAnonymousMode';
import { mentorIsIframe } from '../../utils/shared';

export default function useFreeTrial(manual = true) {
  const auth = useSelector((state) => state.auth.data);
  const [loading, setLoading] = useState(false);
  const [usage, setUsage] = useState(null);
  const [onFreeTrial, setOnFreeTrial] = useState(false);
  const [canUseMain, setCanUseMain] = useState(true);
  const anonymous = useAnonymousMode();

  const dispatch = useDispatch();

  const api = useApi();
  const getFreeTrialCount = async () => {
    if (auth?.tenant?.is_admin) {
      return;
    }

    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/free-usage-count`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      setUsage(response.data);
      dispatch(authActions.freeTrialUsageUpdated(response.data));
    }

    setLoading(false);
  };

  const getFreeTrialStatus = async () => {
    const url = `/api/ai-account/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/free-trial`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data?.is_in_free_trial) {
      setOnFreeTrial(true);
    }
    setLoading(false);
  };

  const getDefaultLLMUsageStatus = async () => {
    const url = `/api/ai-account/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/default-llm-key-usage`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      const data = response.data;
      if (data?.use_main_credentials) {
        setCanUseMain(true);
      }
    }
    setLoading(false);
  };

  const loadFreeTrialDetails = async () => {
    if (
      auth?.tenant?.key === 'main' &&
      !auth?.tenant?.is_admin &&
      process.env.REACT_APP_IBL_ALLOW_FREE_TRIAL_BANNER === 'true'
    ) {
      await getFreeTrialCount();
      setOnFreeTrial(true);
    }
  };

  const getPaymentSuccessCallbackUrl = () => {
    return `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/create-organization?redirect-to=${window.location.origin}`;
  };

  const handleUpgradeClick = async () => {
    const payload = {
      success_url: getPaymentSuccessCallbackUrl(),
      cancel_url: `${window.location.origin}`,
      mode: 'subscription',
      sku: process.env.REACT_APP_IBL_MENTOR_PRODUCT_SKU,
      metered: true,
    };
    const url = `/api/service/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/stripe/checkout-session/`;
    const response = await api.post(url, payload);
    if (response?.data) {
      window.location.assign(response?.data?.redirect_to);
    } else {
      toast.error('Could not create a checkout session');
    }
  };

  useEffect(() => {
    if (!manual && !loading && !anonymous && !mentorIsIframe()) {
      loadFreeTrialDetails();
    }
  }, [auth?.tenant]);

  return {
    loading,
    usage,
    onFreeTrial,
    canUseMain,
    handleUpgradeClick,
    getFreeTrialCount,
  };
}
