import Defaultsuggestedprompttext from '../Defaultsuggestedprompttext/Defaultsuggestedprompttext';
import React from 'react';
import './Aidefaultreponsebox.css';
import { useSelector } from 'react-redux';

const Aidefaultreponsebox = (props) => {
  const defaultMessage =
    'I’m your helpful AI assistant. Not sure where to start? You can try:';

  const mentors = useSelector((state) => state.mentors);

  return (
    <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
      <div className="ai-gif-container">
        <img
          alt=""
          className="image-3"
          loading="lazy"
          src="/images/ai-academy-p-500.png"
        />
      </div>
      <div className="ai-response-text-container">
        <h5 className="ai-default-msg whiten-in-dark-mode">
          {mentors?.mentor?.settings?.initial_message?.trim() ?? defaultMessage}
        </h5>
        <Defaultsuggestedprompttext />
      </div>
    </div>
  );
};

export default Aidefaultreponsebox;
