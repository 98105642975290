import { useSelector } from 'react-redux';
import { BASE_API_URL } from '../../utils/api';
import { useEffect, useState } from 'react';
import useApi from '../api/base/useApi';
import useAnonymousMode from '../iframe/useAnonymousMode';
import { getHostFromUrl, isValidHttpUrl } from '../../utils/shared';

export default function useAiResponseSources(query, streaming) {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const anonymous = useAnonymousMode();
  const api = useApi();

  const URLS_TO_HIDE = [
    'http://blank.page/',
    'https://blank.page/',
    'http://blank.page',
    'https://blank.page',
  ];
  const URLS_TO_DISABLE = [
    BASE_API_URL,
    BASE_API_URL.replace('https://', 'http://'),
  ];

  const loadResponseSources = async () => {
    const url = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/documents/sources/`;
    setLoading(true);
    const payload = {
      query: query?.rawContent || query?.content,
      pathway: mentors?.mentor?.name,
    };
    const response = await api.post(url, payload);
    if (response?.data) {
      setData(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!anonymous && !streaming && !loading && query) {
      loadResponseSources();
    }
  }, [streaming, query]);

  let sources = [];
  if (data) {
    data?.forEach((item) => {
      if (
        item?.source?.trim() &&
        !URLS_TO_HIDE.includes(item?.source?.trim())
      ) {
        sources.push(item?.source?.trim());
      }
    });
  }

  // Filter out API origin URLS
  sources = sources.filter(
    (item) =>
      !(
        item.includes(BASE_API_URL) ||
        item.includes(BASE_API_URL.replace('https://', 'http://'))
      ) && isValidHttpUrl(item)
  );

  const sourcesToDisplay = sources?.map((item) => {
    const sourceItem = {
      url: item,
      status: 'show',
      displayName: getHostFromUrl(item),
    };
    for (const element of URLS_TO_HIDE) {
      if (item.includes(element)) {
        sourceItem.status = 'hide';
        break;
      }
    }

    for (const element of URLS_TO_DISABLE) {
      if (item.includes(element)) {
        sourceItem.status = 'disable';
        break;
      }
    }

    return sourceItem;
  });

  return { sources: sourcesToDisplay, loadResponseSources };
}
