"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderSpanElement = exports.validateUsername = exports.validateEmail = exports.validatePassword = exports.validateLastName = exports.validateFirstName = exports.hasNumber = void 0;
function hasNumber(string) {
    return /\d/.test(string);
}
exports.hasNumber = hasNumber;
function validateFirstName(firstname) {
    let errorMsg = null;
    const isValid = !hasNumber(firstname);
    if (isValid == false) {
        errorMsg = 'First name must not contain any digits.';
    }
    return errorMsg;
}
exports.validateFirstName = validateFirstName;
function validateLastName(lastname) {
    let errorMsg = null;
    const isValid = !hasNumber(lastname);
    if (isValid == false) {
        errorMsg = 'Last name must not contain any digits.';
    }
    return errorMsg;
}
exports.validateLastName = validateLastName;
function validatePassword(password) {
    let errorMsg = null;
    const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])[A-Za-z\d\W\_]{8,}$/;
    const isValid = passwordRegex.test(password);
    if (isValid == false) {
        errorMsg = 'Minimum 8 characters including a number and a lowercase letter';
    }
    return errorMsg;
}
exports.validatePassword = validatePassword;
function validateEmail(email) {
    let errorMsg = null;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const isValid = emailRegex.test(email.trim());
    if (isValid == false) {
        errorMsg = 'Email provided is invalid.';
    }
    return errorMsg;
}
exports.validateEmail = validateEmail;
function validateUsername(username) {
    const usernameRegex = /^[-a-zA-Z0-9_]{2,30}$/;
    const isValid = usernameRegex.test(username.trim());
    let errorMsg = null;
    if (isValid == false) {
        errorMsg =
            'Username can only contain letters (A-Z, a-z), numerals (0-9), underscores (_), and hyphens (-) with a minimum length of 2 and maximum of 30.';
    }
    return errorMsg;
}
exports.validateUsername = validateUsername;
function renderSpanElement(errorMsg, element) {
    if (errorMsg) {
        element.nextElementSibling.innerHTML = errorMsg;
        element.nextElementSibling.style.display = 'block';
        if (!element.classList.contains('sc-form-field-error')) {
            element.classList.add('sc-form-field-error');
        }
    }
    else {
        element.nextElementSibling.style.display = 'none';
        if (element.classList.contains('sc-form-field-error')) {
            element.classList.remove('sc-form-field-error');
        }
    }
    return errorMsg ? false : true;
}
exports.renderSpanElement = renderSpanElement;
