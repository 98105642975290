"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reset_password = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function reset_password(callback = null) {
    const emailOrUsername = (document.querySelector('input[name="email_or_username"]')).value;
    const data = {
        email: emailOrUsername,
    };
    http.post(config_1.default.IBL_EDX.FORGOT_PASSWORD, data, (data) => {
        callback && callback(data);
    }, (error) => {
        callback && callback(error);
    }, false);
}
exports.reset_password = reset_password;
