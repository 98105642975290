import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMentorLLMProviderName } from '../../../utils/shared';
import { toast } from 'react-toastify';
import useApi from '../base/useApi';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { useTriggers } from '../../navigation';
import { navigationActions } from '../../../lib/redux/navigation/slice';
import { mentorActions } from '../../../lib/redux/mentors/slice';

export default function useCreateMentor() {
  const emptyMentor = { name: '', description: '', featured: true };
  const auth = useSelector((state) => state.auth.data);
  const nav = useSelector((state) => state.navigation.data);
  const mentors = useSelector((state) => state.mentors);
  const [mentor, setMentor] = useState(emptyMentor);
  const [mentorImgSrc, setMentorImgSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const update = useUpdateMentorSettings();
  const dispatch = useDispatch();
  const { _handleUpdateModalStatus } = useTriggers();

  const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-with-settings/`;

  const handleLLMKeyCheck = async (data) => {
    const llmProviderName = getMentorLLMProviderName(data?.llm_provider);
    if (llmProviderName) {
      const url = `/api/ai-account/orgs/${auth?.tenant?.key}/credential/?name=${llmProviderName}`;
      const response = await api.get(url);
      if (!response?.data?.length > 0) {
        toast.info(
          'Your mentor does not have an LLM Key set. Please update the key'
        );
        dispatch(mentorActions.editMentorUpdated(data));
        dispatch(mentorActions.editMentorActiveTab('llm'));
        dispatch(mentorActions.editMentorLLMProviderUpdated(llmProviderName));
        dispatch(
          navigationActions.navigationUpdated({
            ...nav,
            modals: {
              ...nav.modals,
              'mentor-creation-modal': false,
              'edit-mentor-modal': true,
              'llm-set-api-key-modal': true,
            },
          })
        );
      }
    }
  };

  const handleUpdateCurrentMentorIfNeeded = (data) => {
    dispatch(mentorActions.currentMentorUpdated(data));
  };

  const handleAddToLocalMentors = (data) => {
    const newMentors = [...(mentors?.mentors?.results ?? []), data];
    dispatch(
      mentorActions.mentorsUpdated({
        ...(mentors?.mentors ?? {}),
        results: newMentors,
      })
    );
  };

  const handleAddToLocalFeaturedMentors = (data) => {
    const newFeaturedMentors = [...(mentors?.featured?.results ?? []), data];
    dispatch(
      mentorActions.featuredMentorsUpdated({
        ...(mentors?.featured ?? {}),
        results: newFeaturedMentors,
      })
    );
  };

  const handleCreateMentorComplete = async (data) => {
    setMentor(emptyMentor);
    toast.success('Mentor has been successfully created');
    handleUpdateCurrentMentorIfNeeded(data);
    handleAddToLocalMentors(data);
    if (mentor?.featured) {
      handleAddToLocalFeaturedMentors(data);
    }
    await handleLLMKeyCheck(data);
    _handleUpdateModalStatus('mentor-creation-modal', false);
  };

  const handleTriggerFileUpload = () => {
    document.getElementById('input-mentor-image-upload').click();
  };

  const handleRemoveImage = () => {
    const imageInput = document.getElementById('input-mentor-image-upload');
    if (imageInput) {
      imageInput.value = '';
    }
    setMentorImgSrc(null);
  };

  const handleUpdateSettings = async (existingMentor) => {
    const form = document.createElement('form');
    const profile_image = document.getElementById('input-mentor-image-upload');
    if (profile_image?.files?.length > 0) {
      form.appendChild(profile_image);
    }
    const formData = new FormData(form);
    if (mentor.featured) {
      formData.append('featured', true);
    }
    return await update.handleUpdateSettings(
      existingMentor,
      formData,
      false,
      true
    );
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files?.length > 0) {
      const mentorImgSrc = URL.createObjectURL(files[0]);
      setMentorImgSrc(mentorImgSrc);
    } else {
      setMentorImgSrc(null);
    }
  };

  const handleCreateMentor = async () => {
    const payload = {
      description: mentor?.description,
      new_mentor_name: mentor?.name,
      display_name: mentor?.name,
      template_name: process.env.REACT_APP_IBL_TEMPLATE_MENTOR,
    };
    setLoading(true);
    const response = await api.post(endpoint, payload);
    if (response?.data) {
      const settings = await handleUpdateSettings(response.data);
      const mentorData = {
        ...response.data,
        metadata: settings?.metadata,
        profile_image: settings?.profile_image,
        settings,
      };
      await handleCreateMentorComplete(mentorData);
      handleRemoveImage();
    } else {
      toast.error(response?.error);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMentor((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setMentor((prevState) => {
      return { ...prevState, [name]: checked };
    });
  };

  return {
    loading,
    mentor,
    handleChange,
    handleCheckChange,
    handleCreateMentor,
    handleFileChange,
    mentorImgSrc,
    handleTriggerFileUpload,
    handleRemoveImage,
  };
}
