"use strict";
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
Object.defineProperty(exports, "__esModule", { value: true });
exports.utils = void 0;
const ibldmcourses = require("./ibl-dm/courses");
const ibldmresources = require("./ibl-dm/resources");
const ibldmplatform = require("./ibl-dm/platform");
const ibldmcred = require("./ibl-dm/credentials");
const ibldmpathway = require("./ibl-dm/pathways");
const ibldmprograms = require("./ibl-dm/programs");
const ibldmskills = require("./ibl-dm/skills");
const ibldmauth = require("./ibl-dm/auth");
const ibldmstripe = require("./ibl-dm/stripe");
const ibledxcourses = require("./ibl-edx/courses");
const ibledxplatform = require("./ibl-edx/platform");
const ibledxmfe = require("./ibl-edx/mfe");
const ibledxpathway = require("./ibl-edx/pathways");
const ibledxregister = require("./ibl-edx/register");
const ibledxusers = require("./ibl-edx/users");
const ibledxtenants = require("./ibl-edx/tenants");
const ibledxpasswordreset = require("./ibl-edx/reset_password");
const ibledxcred = require("./ibl-edx/credentials");
const iblsearch = require("./ibl-search/search");
const iblsearchapi = require("./ibl-search/search_js");
const iblAIsearchapi = require("./ibl-search/ai_search");
const iblwebplatform = require("./ibl-web/platform");
const iblwebvalidation = require("./ibl-web/validation");
const iblwebstripe = require("./ibl-web/stripe");
const iblwebregister = require("./ibl-web/register");
const iblweblogin = require("./ibl-web/login");
const iblwebauth = require("./ibl-web/auth");
const iblwebcredentials = require("./ibl-web/credentials");
const iblaxdfinance = require("./ibl-axd/finance");
const iblaxdlogin = require("./ibl-axd/login");
const iblutils = require("./utils");
const iblwebsocket = require("./websocket");
const dliedxcourses = require("./dli-edx/courses");
const dliedxevents = require("./dli-edx/events");
const http_1 = require("./http");
const iblaxdperlearner = ((_a = window.IblAxdApi) === null || _a === void 0 ? void 0 : _a.hasOwnProperty('PerlearnerApi'))
    ? new window.IblAxdApi.PerlearnerApi()
    : null;
const iblaxdengagement = ((_b = window.IblAxdApi) === null || _b === void 0 ? void 0 : _b.hasOwnProperty('EngagementApi'))
    ? new window.IblAxdApi.EngagementApi()
    : null;
const iblaxdplatform = ((_c = window.IblAxdApi) === null || _c === void 0 ? void 0 : _c.hasOwnProperty('PlatformApi'))
    ? new window.IblAxdApi.PlatformApi()
    : null;
const iblaxdperformance = ((_d = window.IblAxdApi) === null || _d === void 0 ? void 0 : _d.hasOwnProperty('PerformanceApi'))
    ? new window.IblAxdApi.PerformanceApi()
    : null;
const iblaxdaudience = ((_e = window.IblAxdApi) === null || _e === void 0 ? void 0 : _e.hasOwnProperty('AudienceApi'))
    ? new window.IblAxdApi.AudienceApi()
    : null;
const iblaxdaiapi = ((_f = window.IblAxdApi) === null || _f === void 0 ? void 0 : _f.hasOwnProperty('AiApi'))
    ? new window.IblAxdApi.AiApi()
    : null;
const iblaxdv1api = ((_g = window.IblAxdApi) === null || _g === void 0 ? void 0 : _g.hasOwnProperty('V1Api'))
    ? new window.IblAxdApi.V1Api()
    : null;
const iblaxdskillsapi = ((_h = window.IblAxdApi) === null || _h === void 0 ? void 0 : _h.hasOwnProperty('SkillsApi'))
    ? new window.IblAxdApi.SkillsApi()
    : null;
const iblaxdoverviewapi = ((_j = window.IblAxdApi) === null || _j === void 0 ? void 0 : _j.hasOwnProperty('OverviewApi'))
    ? new window.IblAxdApi.OverviewApi()
    : null;
const iblaxdreportapi = ((_k = window.IblAxdApi) === null || _k === void 0 ? void 0 : _k.hasOwnProperty('ReportsApi'))
    ? new window.IblAxdApi.ReportsApi()
    : null;
const iblaxdcredapi = ((_l = window.IblAxdApi) === null || _l === void 0 ? void 0 : _l.hasOwnProperty('CredentialsApi'))
    ? new window.IblAxdApi.CredentialsApi()
    : null;
const iblaxdcoreapi = ((_m = window.IblAxdApi) === null || _m === void 0 ? void 0 : _m.hasOwnProperty('CoreApi'))
    ? new window.IblAxdApi.CoreApi()
    : null;
const iblaxdaimentorapi = ((_o = window.IblAxdApi) === null || _o === void 0 ? void 0 : _o.hasOwnProperty('AiMentorApi'))
    ? new window.IblAxdApi.AiMentorApi()
    : null;
const iblaxdaiindexapi = ((_p = window.IblAxdApi) === null || _p === void 0 ? void 0 : _p.hasOwnProperty('AiIndexApi'))
    ? new window.IblAxdApi.AiIndexApi()
    : null;
exports.utils = require("./utils");
class IblJsClient {
    constructor(IBL_API_CONFIG, GLOBAL_HANDLERS) {
        if (IblJsClient.instance) {
            return IblJsClient.instance;
        }
        this._urlConfig = IBL_API_CONFIG;
        http_1.default.init(IBL_API_CONFIG, GLOBAL_HANDLERS);
        this.ibldmcourses = ibldmcourses;
        this.ibldmresources = ibldmresources;
        this.ibldmplatform = ibldmplatform;
        this.ibldmcred = ibldmcred;
        this.ibldmpathway = ibldmpathway;
        this.ibldmprograms = ibldmprograms;
        this.ibldmskills = ibldmskills;
        this.ibldmauth = ibldmauth;
        this.ibldmstripe = ibldmstripe;
        this.ibledxcourses = ibledxcourses;
        this.ibledxplatform = ibledxplatform;
        this.ibledxmfe = ibledxmfe;
        this.ibledxpathway = ibledxpathway;
        this.ibledxregister = ibledxregister;
        this.ibledxusers = ibledxusers;
        this.ibledxtenants = ibledxtenants;
        this.ibledxpasswordreset = ibledxpasswordreset;
        this.ibledxcred = ibledxcred;
        this.iblsearch = iblsearch;
        this.iblsearchapi = iblsearchapi;
        this.iblAIsearchapi = iblAIsearchapi;
        this.iblwebplatform = iblwebplatform;
        this.iblwebcredentials = iblwebcredentials;
        this.iblwebvalidation = iblwebvalidation;
        this.iblwebstripe = iblwebstripe;
        this.iblwebregister = iblwebregister;
        this.iblweblogin = iblweblogin;
        this.iblwebauth = iblwebauth;
        this.iblaxdfinance = iblaxdfinance;
        this.iblaxdlogin = iblaxdlogin;
        this.iblutils = iblutils;
        this.iblwebsocket = iblwebsocket;
        this.iblaxdperlearner = iblaxdperlearner;
        this.iblaxdengagement = iblaxdengagement;
        this.iblaxdplatform = iblaxdplatform;
        this.iblaxdperformance = iblaxdperformance;
        this.iblaxdaudience = iblaxdaudience;
        this.iblaxdaiapi = iblaxdaiapi;
        this.iblaxdv1api = iblaxdv1api;
        this.iblaxdskillsapi = iblaxdskillsapi;
        this.iblaxdoverviewapi = iblaxdoverviewapi;
        this.iblaxdreportapi = iblaxdreportapi;
        this.iblaxdcredapi = iblaxdcredapi;
        this.iblaxdcoreapi = iblaxdcoreapi;
        this.iblaxdaimentorapi = iblaxdaimentorapi;
        this.dliedxcourses = dliedxcourses;
        this.dliedxevents = dliedxevents;
        this.iblaxdaiindexapi = iblaxdaiindexapi;
        // Save the instance
        IblJsClient.instance = this;
        // Ensure that the constructor returns the Singleton instance
        return this;
    }
    get urlConfig() {
        return this._urlConfig;
    }
    getAxdApis() {
        return [
            this.iblaxdaudience,
            this.iblaxdperformance,
            this.iblaxdperlearner,
            this.iblaxdengagement,
            this.iblaxdplatform,
            this.iblaxdv1api,
            this.iblaxdaiapi,
            this.iblaxdskillsapi,
            this.iblaxdoverviewapi,
            this.iblaxdreportapi,
            this.iblaxdcredapi,
            this.iblaxdcoreapi,
            this.iblaxdaimentorapi,
            this.iblaxdaiindexapi,
        ];
    }
    set isDirectAxdAuth(isDirectAxdAuth) {
        this._isDirectAxdAuth = isDirectAxdAuth;
    }
    get isDirectAxdAuth() {
        return this._isDirectAxdAuth;
    }
    set spaLoginPath(spaLoginPath) {
        this._spaLoginPath = spaLoginPath;
    }
    get spaLoginPath() {
        return this._spaLoginPath;
    }
    initiateAxdClientsWithAxdWebUrl(axdUrl) {
        this.getAxdApis().forEach((api) => {
            api && (api.apiClient.basePath = axdUrl);
        });
    }
    initiateAxdClientsWithToken(axd_token) {
        this.getAxdApis().forEach((api) => {
            api &&
                (api.apiClient.defaultHeaders = {
                    Authorization: 'Token ' + axd_token,
                });
        });
    }
    iblaxdApiCallWrapper(apiCallFunc, ...args) {
        const makeApiCall = (callback, noAuthCallback = null, canRetry = true) => {
            const callbackWrapper = (err, data, response) => {
                var _a;
                if (response.statusCode === 401 || response.statusCode === 403) {
                    if (response.statusCode === 401 && iblutils.isTokenExpired()) {
                        (_a = http_1.default.globalHandlers) === null || _a === void 0 ? void 0 : _a.errors[401]();
                    }
                    if (noAuthCallback) {
                        noAuthCallback(err, data, response);
                        return;
                    }
                    if (this.isDirectAxdAuth) {
                        location.href = `${location.origin}${this.spaLoginPath}?redirect_url=${location.pathname}${location.search}`;
                    }
                    else if (canRetry) {
                        /* Commented out the code below because for subsequent n calls, the request
                            to fetch the new axd token is repeated n times */
                        // getAxdLearnerToken(
                        //   { tenant: getCurentTenant()?.name },
                        //   (axd_token) => {
                        //     this.initiateAxdClientsWithToken(axd_token);
                        //     makeApiCall(callback, false);
                        //   }
                        // );
                    }
                    else {
                        callback(err, data, response);
                    }
                }
                else {
                    callback(err, data, response);
                }
            };
            args.push(callbackWrapper);
            apiCallFunc(args);
        };
        return makeApiCall;
    }
}
exports.default = IblJsClient;
