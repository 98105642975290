import React from 'react';
import { useSelector } from 'react-redux';
import useEditMentor from '../../../hooks/api/mentors/useEditMentor';
import Loader from '../../loader';
import { userIsAdmin } from '../../../utils/shared';
import SubmitBtn from '../../../components/SubmitBtn/SubmitBtn';

export default function MentorSettings() {
  const {
    mentor,
    handleChange,
    handleSubmit,
    handleCheckChange,
    saving,
    handleFileChange,
    mentorImgSrc,
    handleTriggerFileUpload,
    handleRemoveImage,
  } = useEditMentor();
  return (
    <div className="w-layout-vflex edit-mentor-setting-tab">
      <div className="secret-key-form-block edit-mentor-settings-form-block w-form">
        <form
          className="manage-account-form secret-key-form edit-mentor-settings-form"
          data-name="Email Form 2"
          data-wf-element-id="caf6ebbd-0b06-053d-0a1e-1eebea90b02a"
          data-wf-page-id="650305782bc61751e5765214"
          id="email-form-2"
          method="get"
          name="email-form-2"
        >
          <div className="w-layout-hflex flex-block-81">
            <div className="w-layout-vflex flex-block-82">
              <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    Name
                  </label>
                </div>
                <input
                  className="form-input secret-key-form-input edit-mentor-settings-form-input black-bg-white-color-form-field w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 4"
                  id="organisation-name-4"
                  maxLength={256}
                  name="organisation-name-4"
                  defaultValue={mentor?.name}
                  placeholder="Mentor Name"
                  disabled={true}
                  type="email"
                />
              </div>
              <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    Description
                  </label>
                </div>
                <textarea
                  className="edit-mentor-settings-form-textarea black-bg-white-color-form-field textarea-medium-height w-input"
                  data-name="Field 8"
                  id="field-8"
                  maxLength={5000}
                  name="description"
                  value={mentor?.description}
                  onChange={handleChange}
                  placeholder="Mentor Description"
                ></textarea>
              </div>
            </div>
            <div className="w-layout-vflex flex-block-83">
              <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    Image
                  </label>
                </div>
                <div
                  className={`w-layout-hflex mentor-image-uploader ${mentorImgSrc ? '' : 'empty'}`}
                  onClick={mentorImgSrc ? console.log : handleTriggerFileUpload}
                >
                  {mentorImgSrc ? (
                    <div
                      className="w-layout-hflex upload-img-box"
                      data-w-id="3d85f56c-c65d-b956-7f38-bd18ab5e5ba8"
                      style={{
                        background: `url(${mentorImgSrc})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <div className="w-layout-hflex upload-action-btn-container">
                        <img
                          alt=""
                          className="upload-action-btn-icon view"
                          loading="lazy"
                          src="images/eye-1.svg"
                          style={{ display: 'none' }}
                        />
                        <img
                          alt=""
                          className="upload-action-btn-icon remove"
                          loading="lazy"
                          title="Remove image"
                          onClick={handleRemoveImage}
                          src="images/trash-2.svg"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="w-layout-hflex upload-text-box">
                      <div className="text-block-52">+ Upload</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-layout-hflex flex-block-20">
            <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block horizontal-flex gap-20">
              <div className="w-layout-hflex flex-block-19">
                <label className="form-input-label" htmlFor="name-6">
                  Featured Mentor
                </label>
              </div>
              <div className="user-row-switcher-embed justify-start-flex w-embed">
                <label className="user-switcher">
                  <input
                    onChange={handleCheckChange}
                    name="featured"
                    defaultChecked={mentor?.metadata?.featured}
                    type="checkbox"
                  />
                  <span className="user-switcher-slider round"></span>
                </label>
              </div>
            </div>
            <SubmitBtn
              title={
                userIsAdmin()
                  ? 'Save Mentor'
                  : 'This feature is available in our paid tier'
              }
              style={{
                cursor: userIsAdmin() ? 'pointer' : 'not-allowed',
              }}
              onClick={
                userIsAdmin() ? (!saving ? handleSubmit : () => {}) : () => {}
              }
              href="#"
              saving={saving}
            />
          </div>
          <div className="w-embed">
            <input
              accept="image/*"
              className="input-mentor-image-upload"
              id="input-mentor-image-upload"
              name="uploaded_profile_image"
              type="file"
              onChange={handleFileChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
