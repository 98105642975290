import { useEffect, useState } from 'react';
import { isValidHttpUrl } from '../../../utils/shared';
import { useSelector } from 'react-redux';
import useApi from '../base/useApi';

export default function useMentorDataset() {
  const [datasets, setDatasets] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)

  const api = useApi();

  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const nav = useSelector((state) => state.navigation.data);

  const getDatasets = async () => {
    const url = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/documents/pathways/${mentors?.edit?.mentor?.name}/`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      setDatasets(response?.data);
    }
    setHasLoadedOnce(true)
    setLoading(false);
  };

  const getDataSetType = (item) => {
    return isValidHttpUrl(item.url) ? 'URL' : 'TEXT';
  };
  let datasetsToRender = [];

  if (datasets?.length) {
    datasetsToRender = datasets.map((item) => {
      const fileType = item.document_type ?? getDataSetType(item);
      return {
        ...item,
        document_type: fileType,
      };
    });
  }

  if (search && datasetsToRender?.length) {
    datasetsToRender = datasetsToRender.filter((item) =>
      item?.name?.includes(search)
    );
  }

  useEffect(() => {
    getDatasets();
  }, [mentors?.edit?.mentor, nav?.modals?.['dataset-url-resource-modal']]);

  return { loading, datasetsToRender, search, setSearch, getDatasets, hasLoadedOnce };
}
