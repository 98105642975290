import React from "react";
import "./Exportchatmodalbox.css";


const Exportchatmodalbox = props => {
    
    return (
<div className="modal export-modal">
<div className="w-layout-vflex modal-container export-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">Export Chat</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body export-chat-modal-body">
<div className="w-layout-hflex chat-export-description-block">
<div className="chat-export-description">Messages you send after creating your link won't be shared. Anyone with the URL will be able to view the shared chat.</div>
</div>
<div className="w-layout-vflex chat-export-container">
<div className="chat-export-container-top-block">
<div className="w-layout-hflex chat-export-header">
<div className="chat-export-header-block">
<div className="chat-export-icon-container"><img alt="" className="image-26" loading="lazy" src="images/user.svg"/></div>
<p className="paragraph-2">In law what harm could i suffer when i see two people kissing in public</p>
</div>
</div>
<div className="w-layout-hflex chat-export-header chat-export-body">
<div className="chat-export-header-block">
<div className="chat-export-icon-container"><img alt="" className="image-26" loading="lazy" src="images/bot.svg"/></div>
<p className="paragraph-2">As an AI language model, I can provide you with some general information, but please keep in mind that laws vary across jurisdictions, and I am not a lawyer. If you have concerns about a specific jurisdiction, it's always best to consult with a legal professional who can provide advice based on the laws applicable to your situation.<br/><br/>As an AI language model, I can provide you with some general information, but please keep in mind that laws vary across jurisdictions, and I am not a lawyer. If you have concerns about a specific jurisdiction, it's always best to consult with a legal professional who can provide advice based on the laws applicable to your situation.<br/><br/>As an AI language model, I can provide you with some general information, but please keep in mind that laws vary across jurisdictions, and I am not a lawyer. If you have concerns about a specific jurisdiction, it's always best to consult with a legal professional who can provide advice based on the laws applicable to your situation.</p>
</div>
</div>
</div>
<div className="w-layout-hflex chat-export-container-bottom-block">
<div className="w-layout-vflex chat-export-details-block">
<h5 className="chat-export-details-title">Public Kissing: Legal Considerations</h5>
<div className="w-layout-hflex chat-export-details">
<div className="chat-export-details-label name hidden">Miguel AMIGOT</div>
<div className="chat-export-details-label">July 7, 2023</div>
</div>
</div>
<div className="chat-export-icon-container chat-export-more-icon-container"><img alt="" className="image-26 export-chat-more-img" loading="lazy" src="images/more-horizontal-3.svg"/></div>
<div className="w-layout-hflex share-name-anonymous-block hidden">
<div className="w-layout-hflex share-block name"><img alt="" className="share-block-icon" loading="lazy" src="images/user-black.svg"/>
<h6 className="share-block-label">Share your name</h6>
</div>
<div className="w-layout-hflex share-block hidden anonymous"><img alt="" className="share-block-icon" loading="lazy" src="images/user-black.svg"/>
<h6 className="share-block-label">Share anonymously</h6>
</div>
</div>
</div>
</div>
<div className="w-layout-hflex flex-block-17">
<div className="button-with-icon"><img alt="" className="button-icon" loading="lazy" src="images/download.svg" width={70}/>
<div className="button-text">Download Chat</div>
</div>
<div className="button-with-icon"><img alt="" className="button-icon no-invert" loading="lazy" src="images/link.svg" width={70}/>
<div className="button-text">Copy Link</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Exportchatmodalbox;