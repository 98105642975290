import { useSelector } from 'react-redux';
import { useTriggers } from '../../../hooks/navigation';

export default function MentorEmbedWidget({ mentor }) {
  const auth = useSelector((state) => state.auth.data);
  const url = new URL(window.location.href);
  const urlOrigin = url.origin;
  const { handleModalCloseClick } = useTriggers();

  const getUrl = (mode = '', extraBodyClasses = '') => {
    let url = `${urlOrigin}?mentor=${mentor?.name}&tenant=${auth?.tenant?.key}`;
    if (mode === 'anonymous') {
      url += '&mode=anonymous';
    }
    if (extraBodyClasses) {
      url += `&extra-body-classes=${extraBodyClasses}`;
    }
    return url;
  };

  const getEmbedCode = (mode) => {
    return `
            <script>
                window.onload = () => {
                    let iframeContainer = document.createElement('div');
                    iframeContainer.id = 'ibl-chat-widget-container';
                    iframeContainer.style = "position: fixed; border-radius: 13px; bottom: 96px; right: 60px; z-index: 2147483647; width: 400px; height: 82%;box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
                    let bubble = document.createElement('img');
                    bubble.src = '${urlOrigin}/images/ibl-logo-animated.gif';
                    bubble.style = "position: fixed; right: 60px; bottom: 20px; height: 50px;cursor:pointer;";
                    bubble.addEventListener('click', () => {
                        const widget = document.getElementById('ibl-chat-widget-container');
                        if(widget.style.display === 'none'){
                            widget.style.display = '';
                        }else{
                            widget.style.display = 'none';
                        }
                    });
                    let iframe = document.createElement('iframe');
                    iframe.src = '${getUrl(mode, 'iframed-externally')}';
                    iframe.style = "border: 0px white; height:100%;width:100%;border-radius: 13px;";
                
                    iframeContainer.appendChild(iframe);
                    document.body.appendChild(iframeContainer);
                    document.body.appendChild(bubble);
                }
            </script>
        `;
  };

  const handleCopyScript = (mode) => {};

  return (
    <div className="modal embed-mentor-modal">
      <div className="w-layout-vflex modal-container embed-mentor-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Embed Mentor</h3>
          <div onClick={handleModalCloseClick} className="modal-close-wrapper">
            <img src="images/close_1close.png" loading="lazy" alt="" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="w-layout-vflex share-script-container">
            <div className="share-desc-label">
              To add a mentor bubble to the bottom right of your website add
              this script tag to your html
              <br />
            </div>
            <pre
              style={{ whiteSpace: 'nowrap' }}
              className="code-container lighter-grey-bg-dark-mode"
            >
              <code className="code-block">
                {getEmbedCode('authenticated')}
              </code>
            </pre>
            <div
              data-w-id="b0617eed-e4bb-eb50-9b62-14152da483e2"
              onClick={() => handleCopyScript('authenticated')}
              className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode"
            >
              <div className="copy-label">Copy Script</div>
              <img
                src="images/copy.svg"
                loading="lazy"
                alt=""
                className="copy-icon"
              />
              <img
                src="images/check.svg"
                loading="lazy"
                alt=""
                className="copy-icon copied-icon"
              />
            </div>
          </div>

          <hr />

          <div className="w-layout-vflex share-script-container">
            <div className="share-desc-label">
              To add a mentor to your website and allow users to chat
              anonymously add this script tag to your html
              <br />
            </div>
            <pre
              style={{ whiteSpace: 'nowrap' }}
              className="code-container lighter-grey-bg-dark-mode"
            >
              <code className="code-block">{getEmbedCode('anonymous')}</code>
            </pre>
            <div
              data-w-id="b0617eed-e4bb-eb50-9b62-14152da483e2"
              onClick={() => handleCopyScript('anonymous')}
              className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode"
            >
              <div className="copy-label">Copy Script</div>
              <img
                src="images/copy.svg"
                loading="lazy"
                alt=""
                className="copy-icon"
              />
              <img
                src="images/check.svg"
                loading="lazy"
                alt=""
                className="copy-icon copied-icon"
              />
            </div>
          </div>

          <div
            className="w-layout-vflex share-script-container"
            style={{ display: 'none' }}
          >
            <div className="share-desc-label">
              To add the mentor any where on your website, add this iframe to
              your html code
              <br />
            </div>
            <div className="code-container lighter-grey-bg-dark-mode">
              <p className="code-block">
                /
                <br />
              </p>
            </div>
            <div className="w-layout-hflex flex-block-73">
              <div
                data-w-id="9e30f220-f64f-eff8-10c4-2f53ce9b13b4"
                className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode"
              >
                <div className="copy-label">Copy</div>
                <img
                  src="images/copy.svg"
                  loading="lazy"
                  alt=""
                  className="copy-icon"
                />
                <img
                  src="images/check.svg"
                  loading="lazy"
                  alt=""
                  className="copy-icon copied-icon"
                />
              </div>
              <a
                href="https://ibl.ai/"
                target="_blank"
                className="visit-link-block w-inline-block"
              >
                <div className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode">
                  <div className="copy-label">Visit</div>
                  <img
                    src="images/external-link-1.svg"
                    loading="lazy"
                    alt=""
                    className="copy-icon"
                  />
                  <img
                    src="images/check.svg"
                    loading="lazy"
                    alt=""
                    className="copy-icon copied-icon"
                  />
                </div>
              </a>
            </div>
          </div>
          <div
            className="w-layout-vflex share-script-container"
            style={{ display: 'none' }}
          >
            <div className="share-desc-label">
              To add the mentor Iframe any where on your website, add this
              iframe to your html code
              <br />
            </div>
            <div className="code-container lighter-grey-bg-dark-mode">
              <p className="code-block">
                &lt;iframe
                <br />
                src=&quot;/&quot;
                <br />
                width=&quot;100%&quot;
                <br />
                style=&quot;height: 100%; min-height: 700px;
                max-width:380px&quot;
                <br />
                frameborder=&quot;0&quot;
                <br />
                &gt;&lt;/iframe&gt;
              </p>
            </div>
            <div
              data-w-id="89385c18-7511-f41a-5feb-44d6b0968ca5"
              className="w-layout-hflex copy-button all-whiten-in-dark-mode greyish-bg-in-darkmode"
            >
              <div className="copy-label">Copy Iframe</div>
              <img
                src="images/copy.svg"
                loading="lazy"
                alt=""
                className="copy-icon"
              />
              <img
                src="images/check.svg"
                loading="lazy"
                alt=""
                className="copy-icon copied-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
