import PageLoader from '../loader/page-loader';
import { useEffect } from 'react';
import { redirectToAuth } from '../../utils/auth';

export default function Login() {
  useEffect(() => {
    localStorage.clear();
    redirectToAuth(true);
  }, []);
  return <PageLoader />;
}
