import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../../lib/redux/mentors/slice';

export default function useFeaturedMentors() {
  const mentors = useSelector((state) => state.mentors);
  const dispatch = useDispatch();
  const handleAddMentorToFeatured = (mentor) => {
    const featuredMentors = [...(mentors?.featured?.results ?? []), mentor];
    dispatch(
      mentorActions.featuredMentorsUpdated({ ...mentors?.featured?? {}, results: featuredMentors })
    );
  };

  return { handleAddMentorToFeatured };
}
