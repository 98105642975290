import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import { navigationActions } from '../../lib/redux/navigation/slice';

export default function useNewChat() {
  const chat = useSelector((state) => state.chat.data);
  const nav = useSelector((state) => state.navigation.data);
  const dispatch = useDispatch();

  const handleNewChatBtnClick = () => {
    dispatch(chatActions.chatUpdated({ ...chat, sequence: chat.sequence + 1 }));
    dispatch(navigationActions.navigationUpdated({ ...nav, sidebar: true }));
  };

  return { handleNewChatBtnClick };
}
