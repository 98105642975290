import IblJsClient from '@iblai/ibl-web-core-frontend';

export const BASE_API_URL = process.env.REACT_APP_IBL_AXD_URL;

const api = new IblJsClient({
  IBL_API_PROXY_URL: process.env.REACT_APP_IBL_WEB_URL,
  IBL_LMS_URL: process.env.REACT_APP_IBL_LMS_URL,
});
api.initiateAxdClientsWithAxdWebUrl(BASE_API_URL);
api.isDirectAxdAuth = process.env.REACT_APP_IBL_IS_DIRECT_AXD_AUTH === 'true';
api.spaLoginPath = process.env.REACT_APP_IBL_SPA_LOGIN_PATH;
export default api;
