import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function useEditMentorTabs() {
  const edit = useSelector((state) => state.mentors.edit);
  const [tab, setTab] = useState(edit?.activeTab || 'settings');
  const tabs = [
    { key: 'settings', title: 'Settings' },
    { key: 'llm', title: 'LLM' },
    { key: 'prompts', title: 'Prompts' },
    { key: 'datasets', title: 'Datasets' },
    { key: 'embed', title: 'Embed' },
  ];

  return { tab, setTab, tabs };
}
