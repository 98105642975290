import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { toast } from 'react-toastify';
import { useTriggers } from '../../navigation';
import usePrompts from './usePrompts';
import useMentorSuggestedPrompts from '../mentors/useMentorSuggestedPrompts';
import { mentorActions } from '../../../lib/redux/mentors/slice';

export default function useCreatePrompt() {
  const emptyPrompt = {
    prompt: '',
    description: '',
    category: '',
  };
  const [prompt, setPrompt] = useState(emptyPrompt);
  const [creating, setCreating] = useState(false);
  const dispatch = useDispatch();
  const { handlePromptDataSuccess } = usePrompts();
  const { loadSuggestedPrompts } = useMentorSuggestedPrompts();

  const auth = useSelector((state) => state.auth.data);
  const prompts = useSelector((state) => state.prompts.data);
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const api = useApi();
  const { _handleUpdateModalStatus } = useTriggers();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPrompt((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSaveMentorSuggestedPrompt = async () => {
    const endpoint = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${mentor?.unique_id}/suggested-prompts/`;
    const payload = {
      prompt: prompt.description,
    };
    await makeRequest(endpoint, payload);
    const suggestedPrompts = await loadSuggestedPrompts(mentor?.unique_id);
    const newEditMentor = { ...mentor, suggested_prompts: suggestedPrompts };
    dispatch(mentorActions.editMentorUpdated(newEditMentor));
  };

  const addNewPromptToStore = (data) => {
    const updatedPrompts = [...prompts.loadedPrompts, data];
    handlePromptDataSuccess(updatedPrompts);
  };

  const makeRequest = async (url, payload) => {
    setCreating(true);

    const response = await api.post(url, payload);
    if (response?.data) {
      toast.success('Prompt has been saved!');
      addNewPromptToStore(response?.data);
      _handleUpdateModalStatus('add-new-prompt-modal', false);
    } else {
      toast.error(response?.error);
    }

    setCreating(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (mentor) {
      await handleSaveMentorSuggestedPrompt();
      return;
    }
    if (!prompt?.category) {
      toast.warn('Please select a prompt category!');
      return;
    }
    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/`;
    const payload = {
      ...prompt,
      platform: auth?.tenant?.key,
      is_system: false,
    };
    await makeRequest(url, payload);
  };

  return { handleChange, handleSubmit, creating };
}
