"use strict";
/**
 * @namespace iblwebauth
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAxdLearnerToken = exports.logout = exports.resetPassword = exports.forgetPassword = exports.registerUser = exports.initializeLocalStorageWithAuthData_old = exports.completeLogin_old = exports.initializeLocalStorageWithAuthData = exports.completeLogin = exports.login = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const utils_1 = require("../utils");
const http = new http_1.default();
/**
 * Login a user to ibl web
 * @function login
 * @memberof iblwebauth
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {String} data.password User's password
 * @param {callback} successCb
 * @param {callback} errorCb
 * @param {Object} param
 * @param {String} [param.tenant=main] IBL tenant
 * @param {String} param.username username to make this request on behalf of
 *
 * @example <caption>Login user to ibl web</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.login(
 *    {username: "example", "password": "not-secure"},
 *    (data) => console.log(data)
 * )
 * ```
 */
function login(data, successCb = null, errorCb = null, params = {}) {
    http.post(config_1.default.LOGIN + (0, utils_1.buildQueryParams)(params), data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.login = login;
/**
 * Complete a user login process during SSO. This will save the user token to the local storage
 * and will redirect the user if redirect params are set
 * @function completeLogin
 * @memberof iblwebauth
 *
 * @example <caption>Complete the user SSO login</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.completeLogin()
 * ```
 */
function completeLogin(callback) {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    const dmToken = urlParams.get('dm_token');
    urlParams.delete('token');
    let path = '';
    if (urlParams.has('redirect-path')) {
        path += urlParams.get('redirect-path') + '&';
        urlParams.delete('redirect-path');
        urlParams.forEach((value, key) => {
            path += `${key}=${value}&`;
        });
    }
    // initializeLocalStorageWithAuthData(token, dmToken);
    if (path) {
        path = path.replace(/\s+/g, '+');
        if (path.includes('?redirect')) {
            path = path.replace('?redirect', '&redirect');
        }
        location.replace(`.${path}`);
    }
    else {
        location.replace('/');
    }
    callback && callback(token);
}
exports.completeLogin = completeLogin;
/**
 * Decode the token passed to it and use the token to initialize auth fields in localStorage
 * @function initializeLocalStorageWithAuthData
 * @memberof iblwebauth
 *
 * @example <caption>Decode the token passed to it and use the token to initialize auth fields in localStorage</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.initializeLocalStorageWithAuthData(token)
 * ```
 */
function initializeLocalStorageWithAuthData(axdToken, dmToken, user, tenants, selectedTenant) {
    localStorage.setItem('axd_token', axdToken.token);
    localStorage.setItem('axd_token_expires', axdToken.expires);
    localStorage.setItem('dm_token', dmToken.token);
    localStorage.setItem('dm_token_expires', dmToken.expires);
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('tenants', JSON.stringify(tenants));
    (0, utils_1.saveUserTenantsDataToLocalStorage)(tenants, selectedTenant);
}
exports.initializeLocalStorageWithAuthData = initializeLocalStorageWithAuthData;
/**
 * Complete a user login process during SSO. This will save the user token to the local storage
 * and will redirect the user if redirect params are set
 * @function completeLogin
 * @memberof iblwebauth
 *
 * @example <caption>Complete the user SSO login</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.completeLogin()
 * ```
 */
function completeLogin_old(callback) {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    const dmToken = urlParams.get('dm_token');
    urlParams.delete('token');
    let path = '';
    if (urlParams.has('redirect-path')) {
        path += urlParams.get('redirect-path') + '&';
        urlParams.delete('redirect-path');
        urlParams.forEach((value, key) => {
            path += `${key}=${value}&`;
        });
    }
    initializeLocalStorageWithAuthData_old(token, dmToken);
    if (path) {
        path = path.replace(/\s+/g, '+');
        if (path.includes('?redirect')) {
            path = path.replace('?redirect', '&redirect');
        }
        location.replace(`.${path}`);
    }
    else {
        location.replace('/');
    }
    callback && callback(token);
}
exports.completeLogin_old = completeLogin_old;
/**
 * Decode the token passed to it and use the token to initialize auth fields in localStorage
 * @function initializeLocalStorageWithAuthData
 * @memberof iblwebauth
 *
 * @example <caption>Decode the token passed to it and use the token to initialize auth fields in localStorage</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.initializeLocalStorageWithAuthData(token)
 * ```
 */
function initializeLocalStorageWithAuthData_old(token, dmToken) {
    var _a;
    const tokenData = (0, utils_1.parseJwt)(token || '');
    localStorage.setItem('token', token);
    const axd_token = tokenData.data.axd_token;
    const axd_token_expires = tokenData.data.axd_token_expires;
    localStorage.setItem('axd_token', axd_token);
    localStorage.setItem('dm_token', dmToken);
    localStorage.setItem('axd_token_expires', axd_token_expires);
    localStorage.setItem('userData', JSON.stringify(tokenData.data.user));
    localStorage.setItem('ibl_axd_url', tokenData.data.ibl_axd_url);
    localStorage.setItem('ibl_web_url', tokenData.data.ibl_web_url);
    localStorage.setItem('ibl_edx_url', tokenData.data.ibl_edx_url);
    localStorage.setItem('ibl_search_url', tokenData.data.ibl_search_url);
    localStorage.setItem('current_tenant', JSON.stringify(tokenData.data.current_tenant));
    localStorage.setItem('tenants', JSON.stringify(tokenData.data.tenants));
    localStorage.setItem('tenant', (_a = tokenData.data.current_tenant) === null || _a === void 0 ? void 0 : _a.key);
}
exports.initializeLocalStorageWithAuthData_old = initializeLocalStorageWithAuthData_old;
/**
 * Register a new user to the platform
 * @function registerUser
 * @memberof iblwebauth
 *
 * @param {Object} data
 * @param {String} data.email User's email
 * @param {String} data.username User's username
 * @param {String} data.password User's password
 * @param {String} data.name User's full name
 * @param {String} [data.company=""] User's Company
 * @param {callback} successCb
 * @param {callback} errorCb
 * @param {Object} param
 * @param {String} [param.tenant=main] IBL tenant
 * @param {String} param.username username to make this request on behalf of
 * @example <caption>Register a user</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.registerUser({
 *  email: user@example.com
 *  username: example
 *  password: not-secure,
 *  name: "New User",
 *  company: BOB
 * })
 * ```
 */
function registerUser(data, successCb = null, errorCb = null, params = {}) {
    http.post(config_1.default.REGISTRATION + (0, utils_1.buildQueryParams)(params), data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.registerUser = registerUser;
/**
 * Initiate a forget user password operation.
 * An email with reset password instructions will be sent to user whose email is provided
 * @function forgetPassword
 * @memberof iblwebauth
 *
 * @param {Object} data
 * @param {String} data.email User's email
 * @param {callback} successCb
 * @param {callback} errorCb
 * This usage:
 *
 * ```js
 * iblwebauth.forgetPassword({
 *  email: user@example.com
 * })
 * ```
 */
function forgetPassword(data, successCb = null, errorCb = null) {
    let url = config_1.default.IBL_EDX.RESET_PASSWORD;
    let requestData = {
        email: data.email,
    };
    http.post(url, requestData, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.forgetPassword = forgetPassword;
/**
 * Reset the user's password. An email will be sent to the user to confirm password change
 * @function resetPassword
 * @memberof iblwebauth
 *
 * @param {Object} data
 * @param {String} data.password User's new password
 * @param {String} data.reset_password_token The token to use for the password reset operation
 * @param {callback} successCb
 * @param {callback} errorCb
 * @param {Object} param
 * @param {String} [param.tenant=main] IBL tenant
 * @param {String} param.username username to make this request on behalf of
 * @example <caption>Reset password of user to <new-not-secure></caption>
 * This usage:
 *
 * ```js
 * iblwebauth.resetPassword({
 *  password: not-secure,
 *  reset_password_token: token
 * })
 * ```
 */
function resetPassword(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_WEB.RESET_PASSWORD, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false);
}
exports.resetPassword = resetPassword;
/**
 * Logout a user from the entire platform
 * @function logout
 * @memberof iblwebauth
 * @param {String} redirect_url URL the user should be brought back to after logout
 * @example <caption>Logout user</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.logout({}, (redirectUri) => window.location.href = redirectUri)
 * ```
 */
function logout(data, successCb, errorCb) {
    http.post(config_1.default.LOGOUT, data, (data) => {
        localStorage.clear();
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.logout = logout;
/**
 * Get learner token from axd
 * @function getAxdLearnerToken
 * @memberof iblwebauth
 * @param {Object} data
 * @param {String} data.tenant Tenant for which you want to get token for
 * @example <caption>Get learner token from axd</caption>
 * This usage:
 *
 * ```js
 * iblwebauth.getAxdLearnerToken({tenant: "tenantA"}, (axd_token) => console.log(axd_token))
 * ```
 */
function getAxdLearnerToken(data, successCb, errorCb = null) {
    http.get(config_1.default.IBL_AXD.GET_LEARNER_TOKEN, data, (axd_token) => {
        localStorage.setItem('axd_token', axd_token);
        successCb && successCb(axd_token);
    }, (error) => {
        errorCb && errorCb(error);
    });
}
exports.getAxdLearnerToken = getAxdLearnerToken;
