import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useUpdateLocalLLMCredStatus from './useUpdateLocalLLMCredStatus';
import { userIsAdmin } from '../../utils/shared';
import useApi from '../api/base/useApi';

export default function useLLMCredItem(llm) {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const [loading, setLoading] = useState(false);

  const api = useApi();

  const { handleUpdateLLMCredItemStatus } = useUpdateLocalLLMCredStatus();

  const loadLLMCred = async () => {
    const endpoint = `/api/ai-account/orgs/${auth?.tenant?.key}/credential/?name=${llm?.name}`;
    setLoading(true);
    const response = await api.get(endpoint);
    if (response?.data && response?.data?.length > 0) {
      handleUpdateLLMCredItemStatus(llm.name);
    }
    setLoading(false);
  };

  useEffect(() => {
    const storeLLM = mentors?.llms?.find((item) => item.name === llm.name);
    if (userIsAdmin()) {
      loadLLMCred();
    }
  }, []);

  return { loading };
}
