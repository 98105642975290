import Helpmenudropdownbox from '../Helpmenudropdownbox/Helpmenudropdownbox';
import Settingsmenudropdownbox from '../Settingsmenudropdownbox/Settingsmenudropdownbox';
import Usermenudropdownbox from '../Usermenudropdownbox/Usermenudropdownbox';

import React from 'react';
import './Header.css';
import { useSelector } from 'react-redux';
import { useMenus, useTriggers } from '../../hooks/navigation';
import useMentorSlug from '../../hooks/api/mentors/useMentorSlug';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import useUserProfile from '../../hooks/user/useUserProfile';
import Profile from '../../modules/profile';

const PROMPT_GALLERY_ENABLED =
  process.env.REACT_APP_IBL_ENABLE_PROMPT_GALLERY === 'true';

const Header = (props) => {
  const header = useSelector((state) => state.navigation.data);
  const auth = useSelector((state) => state.auth?.data);
  const anonymous = useAnonymousMode();
  const instructorMode = useSelector(
    (state) => state.navigation.instructorMode
  );
  const mentors = useSelector((state) => state.mentors);
  const slug = useMentorSlug(mentors?.mentor);
  const menus = useMenus();
  const {
    handleSettingMenuBtnClick,
    handleUserProfileMenuBtnClick,
    handleFeaturedMentorClick,
    handlePromptGalleryBtnClick,
    handleHelpMenuBtnClick,
    handleUserTypeSwitchChange,
    handleHeaderMenuToggleClick,
  } = useTriggers();

  const userProfile = useUserProfile();

  const headerLogoTitle = process.env.REACT_APP_IBL_HEADER_LOGO_TITLE;

  let profileImageUrl = mentors?.mentor?.settings?.profile_image;
  if (profileImageUrl && !profileImageUrl.includes('s3.amazonaws.com')) {
    profileImageUrl += `?username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`;
  }

  return (
    <section className="header">
      <div className="left-hand-side">
        {!anonymous && (
          <div
            className="menu-toggler greyish-bg-in-darkmode"
            onClick={handleHeaderMenuToggleClick}
            prop-events-value-onclick="handleHeaderMenuToggleClick"
          >
            <img
              alt=""
              className="whiten-in-dark-mode"
              loading="lazy"
              src="/images/align-justify.svg"
            />
          </div>
        )}

        <div
          className="logo-container"
          style={{ marginLeft: anonymous ? '20px' : '' }}
        >
          <img alt="" loading="lazy" src="/images/logo.gif" width={20} />
          <div className="text-block whiten-in-dark-mode title-logo-container">
            {headerLogoTitle}
          </div>
        </div>
        <div
          className="ai-expert-dropdown w-dropdown"
          data-delay={0}
          data-hover="false"
        >
          <div
            className="ai-expert-dropdown-toggle all-whiten-in-dark-mode w-dropdown-toggle"
            onClick={anonymous ? () => {} : handleFeaturedMentorClick}
            prop-events-value-onclick="handleFeaturedMentorClick"
          >
            <div className="icon ai-expert-dropdown-icon w-icon-dropdown-toggle"></div>
            <div className="w-layout-hflex ai-expert-element-block black-bg-dark-mode greyish-bg-in-darkmode selected-ai-expert-block">
              <div className="w-layout-hflex expert-profile-container math-teacher"></div>
              <div className="w-layout-vflex ai-expert-desc-block selected-ai-expert-desc-block">
                <h5 className="ai-expert-title">Math Teacher</h5>
                <div className="ai-expert-desc">miguel@outlook.com</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-layout-hflex ai-expert-header-btn-container all-whiten-in-dark-mode"
          data-w-id="e935f37f-d05f-5278-760c-8d28ffd483e5"
          onClick={anonymous ? () => {} : handleFeaturedMentorClick}
          prop-events-value-onclick="handleFeaturedMentorClick"
          style={{ display: anonymous ? 'none' : 'flex' }}
        >
          <div className="w-layout-hflex flex-block-70">
            <div className="w-layout-vflex active-featured-mentor-block">
              <img
                alt=""
                className="active-featured-mentor-img no-filter-applied-in-darkmode"
                loading="lazy"
                src={profileImageUrl ?? '/images/1.jpg'}
              />
              <div
                title={mentors?.mentor?.name}
                className="w-layout-vflex active-featured-mentor-desc-block"
                style={{ display: anonymous ? 'flex' : '' }}
              >
                <h6 className="active-featured-mentor-desc-header">
                  {mentors?.mentor?.name}
                </h6>
                {/*<div
                  style={{ display: anonymous ? 'none' : '' }}
                  className="active-featured-mentor-desc-label"
                >
                  {slug}
                </div>*/}
              </div>
              {!anonymous && (
                <img
                  alt=""
                  className="image-48"
                  loading="lazy"
                  src="/images/chevron-down-3.svg"
                />
              )}
            </div>
          </div>
          <div className="w-layout-hflex default-featured-mentor-label-block">
            <div className="ai-expert-choose-label">FEATURED MENTORS</div>
            <img
              alt=""
              className="image-48"
              loading="lazy"
              src="/images/chevron-down-3.svg"
            />
          </div>
        </div>
      </div>
      <div className="right-hand-side">
        {auth?.tenant?.is_admin && (
          <div className="w-layout-hflex switcher-container">
            <h5 className="switch-label-txt whiten-in-dark-mode">Learner</h5>
            <div className="switch-html-embed w-embed">
              <label className="switch learner-instructor-switch">
                <input
                  defaultChecked={instructorMode}
                  onClick={handleUserTypeSwitchChange}
                  prop-events-value-onchange="handleUserTypeSwitchChange"
                  type="checkbox"
                />
                <span className="slider round">
                  <img
                    alt=""
                    className="left-img"
                    src="/custom-assets/img/graduation-cap.svg"
                  />
                  <img
                    alt=""
                    className="right-img"
                    src="/custom-assets/img/presentation.svg"
                  />
                </span>
              </label>
            </div>
            <h5 className="switch-label-txt whiten-in-dark-mode instructor-label">
              Instructor
            </h5>
          </div>
        )}
        <div className="w-layout-hflex header-action-btn-block">
          {!anonymous && PROMPT_GALLERY_ENABLED && (
            <div
              className="w-layout-hflex header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode display-only-on-mobile"
              data-w-id="5e893514-6e31-e1d3-c0f4-275ac21ca450"
              onClick={handlePromptGalleryBtnClick}
              prop-events-value-onclick="handlePromptGalleryBtnClick"
            >
              <img
                alt=""
                className="ai-expert-header-action-icon"
                loading="lazy"
                src="/images/terminal-square-1.svg"
              />
            </div>
          )}
          {
            <div
              className="header-settings-dropdown display-only-on-mobile w-dropdown"
              data-delay={0}
              data-hover="false"
            >
              <div
                onClick={handleSettingMenuBtnClick}
                className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode w-dropdown-toggle"
                prop-events-value-onclick="handleSettingMenuBtnClick"
              >
                <img
                  alt=""
                  className="ai-expert-header-action-icon"
                  loading="lazy"
                  src="/images/cog.svg"
                />
              </div>
              {header?.menus?.settings && (
                <div ref={menus?.refs.settings}>
                  <Settingsmenudropdownbox />
                </div>
              )}
            </div>
          }
        </div>
        {!anonymous && !userProfile?.hide && <Profile />}
        <div
          className="w-layout-hflex header-action-btn-block info-header-action-btn-block"
          style={{ display: 'none' }}
        >
          <div
            className="header-help-dropdown w-dropdown"
            data-delay={0}
            data-hover="false"
          >
            <div
              className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode w-dropdown-toggle"
              onClick={handleHelpMenuBtnClick}
              prop-events-value-onclick="handleHelpMenuBtnClick"
            >
              <img
                alt=""
                className="ai-expert-header-action-icon"
                loading="lazy"
                src="/images/more-vertical.svg"
              />
            </div>
            {header?.menus?.help && (
              <div ref={menus?.refs.help}>
                <Helpmenudropdownbox />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
