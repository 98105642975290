import React, { useState } from 'react';
import './Topheader.css';
import useFreeTrial from '../../hooks/freetrial/useFreeTrial';

const Topheader = (props) => {
  const { handleTopHeaderCloseClick, enabled, usage } = useFreeTrial();
  return (
    <section className={`site-top-header ${enabled ? '' : 'hidden'}`}>
      <div className="w-layout-hflex site-top-header-container">
        <div
          className="w-layout-hflex top-header-icon-container"
          data-w-id="16213f3d-be52-d559-0a8b-d87aa0856154"
        >
          <img
            alt=""
            className="top-header-img"
            height={20}
            loading="lazy"
            src="images/alert-circle.svg"
          />
          <div className="tooltip tooltip-trial">
            You are on a free trial and you have {usage?.count} questions left
            before you are prompted to make a payment.
          </div>
        </div>
        <div className="top-header-text">You are on a free trial</div>
        <a
          className="top-header-question-link w-inline-block"
          href="https://ibl.ai/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="top-header-text pill">
            {usage?.count} questions left
          </div>
        </a>
        <div
          className="w-layout-hflex top-header-closer-container"
          onClick={handleTopHeaderCloseClick}
          prop-events-value-onclick="handleTopHeaderCloseClick"
        >
          <img
            alt=""
            className="top-header-closer-icon"
            loading="lazy"
            src="images/x.svg"
          />
        </div>
      </div>
    </section>
  );
};

export default Topheader;
