"use strict";
/**
 * @namespace iblwebcredentials
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.addCredentialButtons = void 0;
/**
 * Adds credential buttons to the credential page
 * @function addCredentialButtons
 * @memberof iblwebcredentials
 *
 * @param {Object} data
 * @param {Boolean} data.linkedin LinkedIn button
 * @param {Boolean} data.download Download button
 * @param {String} data.id Credential ID
 * @param {Object} filteredResult Credential object


 *
 * @example <caption>Add credential buttons</caption>
 * This usage:
 *
 * ```js
 * iblwebcredentials.addCredentialButtons(
 *    {linkedin: true, "download": true, "id": "1234"},
 *    {
        entityId: "uqQDbhIzQsCbeJ29hKe9IQ",
        issuedOn: "2024-02-27 21:18:32.535769+00:00",
        credentialDetails: {
        entityId: "o7eWz8NRTxOsdzZUEApasA",
        ...
      }
 * )
 * ```
 */
function addCredentialButtons(data = null, filteredResult = null) {
    let courseName = filteredResult.course.name;
    let issueDate = filteredResult.issuedOn;
    let date = new Date(issueDate);
    let monthNum = date.getMonth() + 1;
    let year = date.getFullYear();
    if (data.linkedin) {
        var linkedinButtonHTML = `
    <div class="linkedin_btn-3">
        <a href="" target="_blank" class="link-block-9 w-inline-block">
            <div class="link-block soc lnkd">
                <i class="fab fa-linkedin-in"></i>
            </div>
            <div class="button social_btn lnk">Add to Profile</div>
        </a>
    </div>`;
        $(".credential-buttons").append(linkedinButtonHTML);
        const certUrl = encodeURIComponent(window.location.href);
        let linkedinURL = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${courseName}&issueYear=${year}&issueMonth=${monthNum}&certUrl=${certUrl}&certId=${data.id}&organizationId=3608`;
        $(".linkedin_btn-3 a").attr("href", linkedinURL);
    }
    if (data.download) {
        var downloadButtonHTML = `
    <div class="download-btn-3 download-cert">
        <a class="link-block-9 w-inline-block">
            <div class="link-block soc lnkd">
                <i class="fas fa-download"></i>
            </div>
            <div class="button social_btn lnk">Download</div>
        </a>
    </div>`;
        // Append the Download button HTML to the credential-buttons div
        $(".credential-buttons").append(downloadButtonHTML);
        // Now, select the actual DOM element, not the HTML string
        $(".download-cert").on("click", function () {
            // Apply print-specific styles
            const originalDisplayStyle = document.body.style.display;
            document.body.style.display = "block"; // Ensure body is visible for printing
            // Print the document
            window.print();
            // Restore original display style after printing is done
            document.body.style.display = originalDisplayStyle;
        });
    }
}
exports.addCredentialButtons = addCredentialButtons;
