import { useEffect } from 'react';
import PageLoader from '../loader/page-loader';
import { useParams } from 'react-router-dom';
import { axdTokenIsExpired, redirectToAuth } from '../../utils/auth';

export default function Platform() {
  const pathParams = useParams();
  const redirectPath = `/platform/${pathParams.platform}/${pathParams.mentor}`;
  const redirectUrl = `${window.location.origin}`;

  const handlePlatformRequest = () => {
    const currentTenant = localStorage.getItem('tenant');
    if (pathParams.platform !== currentTenant || axdTokenIsExpired()) {
      localStorage.setItem('redirect-path', redirectPath);
      redirectToAuth(true, redirectUrl, pathParams.platform);
    } else {
      const accessCompleteUrl = `/?mentor=${pathParams.mentor}`;
      window.location.assign(accessCompleteUrl);
    }
  };

  useEffect(() => {
    handlePlatformRequest();
  }, []);

  return (
    <>
      <PageLoader />
    </>
  );
}
