"use strict";
/**
 * @namespace iblweblogin
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.completeLogin = exports.login = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const utils_1 = require("../utils");
const http = new http_1.default();
/**
 * Login a user to ibl web
 * @function login
 * @memberof iblweblogin
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {String} data.password User's password
 * @param {callback} successCb
 * @param {callback} errorCb
 * @param {Object} param
 * @param {String} [param.tenant=main] IBL tenant
 * @param {String} param.username username for
 *
 * @example <caption>Login user to ibl web</caption>
 * This usage:
 *
 * ```js
 * iblweblogin.login(
 *    {username: "example", "password": "not-secure"},
 *    (data) => console.log(data)
 * )
 * ```
 */
function login(data, successCb = null, errorCb = null, params = {}) {
    const urlParams = new URLSearchParams(window.location.search);
    let tenant = urlParams.get('tenant');
    tenant = tenant ? tenant : 'main';
    localStorage.setItem('tenant', tenant);
    http.post(config_1.default.LOGIN + (0, utils_1.buildQueryParams)(params), data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false);
}
exports.login = login;
/**
 * Complete a user login process during SSO. This will save the user token to the local storage
 * and will redirect the user if redirect params are set
 * @function completeLogin
 * @memberof iblweblogin
 *
 * @example <caption>Complete the user SSO login</caption>
 * This usage:
 *
 * ```js
 * iblweblogin.completeLogin()
 * ```
 */
function completeLogin() {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    urlParams.delete('token');
    let path = '';
    if (urlParams.has('redirect-path')) {
        path += urlParams.get('redirect-path') + '&';
        urlParams.delete('redirect-path');
        urlParams.forEach((value, key) => {
            path += `${key}=${value}&`;
        });
    }
    const tokenData = (0, utils_1.parseJwt)(token || '');
    localStorage.setItem('token', token);
    const axd_token = tokenData.data.axd_token;
    // initiateAxdClientsWithToken(axd_token);
    localStorage.setItem('axd_token', axd_token);
    localStorage.setItem('userData', JSON.stringify(tokenData.data.user));
    localStorage.setItem('ibl_axd_url', tokenData.data.ibl_axd_url);
    localStorage.setItem('ibl_web_url', tokenData.data.ibl_web_url);
    localStorage.setItem('ibl_edx_url', tokenData.data.ibl_edx_url);
    localStorage.setItem('ibl_search_url', tokenData.data.ibl_search_url);
    localStorage.setItem('current_tenant', JSON.stringify(tokenData.data.current_tenant));
    localStorage.setItem('tenants', JSON.stringify(tokenData.data.tenants));
    localStorage.setItem('tenant', tokenData.data.current_tenant.key);
    if (path) {
        path = path.replace(/\s+/g, '+');
        if (path.includes('?redirect')) {
            path = path.replace('?redirect', '&redirect');
        }
        location.replace(`.${path}`);
    }
    else {
        location.replace('./');
    }
}
exports.completeLogin = completeLogin;
