"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFromStripe = exports.postToStripe = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function postToStripe(data, successCb = null, errorCb = null) {
    let headers = true;
    if (data.callback && data.callback === "createFreeTrialSubscription") {
        headers = false;
    }
    http.post(config_1.default.IBL_WEB.POST_TO_STRIPE, data, successCb, errorCb, headers);
}
exports.postToStripe = postToStripe;
function getFromStripe(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_WEB.GET_FROM_STRIPE, data, successCb, errorCb);
}
exports.getFromStripe = getFromStripe;
