import Aireponsebox from '../../../components/Aireponsebox/Aireponsebox';
import React from 'react';

export default function AIResponse({ message, userQuery, streaming }) {
  return (
    <Aireponsebox
      userQuery={userQuery}
      message={message}
      streaming={streaming}
    />
  );
}
