import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import navigationSlice from './navigation/slice';
import authSlice from './auth/slice';
import mentorSlice from './mentors/slice';
import chatSlice from './chat/slice';
import promptSlice from './prompts/slice';
import usersSlice from './users/slice';

const appReducer = combineReducers({
  navigation: navigationSlice,
  auth: authSlice,
  mentors: mentorSlice,
  chat: chatSlice,
  prompts: promptSlice,
  users: usersSlice,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== 'production'
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});
