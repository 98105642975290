import Promptbox from '../Promptbox/Promptbox';
import React from 'react';
import './Promptgallerycontentsbox.css';
import { useTriggers } from '../../hooks/navigation';

const Promptgallerycontentsbox = (props) => {
  const prompts = props.prompts;
  const { handleAddNewPromptBtnClick } = useTriggers();
  return (
    <div
      className="w-layout-vflex prompt-category-container"
      map=""
      map-value="prompts"
    >
      <h5 className="prompt-category-container-title">{props.category}</h5>
      <div className="w-layout-grid prompt-gallery-grid">
        {prompts?.map((item) => (
          <Promptbox key={item.id} prompt={item} />
        ))}
        <div
          className="w-layout-vflex prompt-grid-element add-prompt-grid-element light-grey-bg-dark-mode greyish-bg-in-darkmode add-prompt-grid-element-1"
          data-w-id="b44be262-c2f3-c32d-452e-d05fbfdb71ec"
          id="w-node-b44be262-c2f3-c32d-452e-d05fbfdb71ec-e5765214"
          style={{ padding: '20px 0px 20px 0' }}
          onClick={handleAddNewPromptBtnClick}
        >
          <div className="w-layout-hflex flex-block-18">
            <img
              alt=""
              className="image-28"
              loading="lazy"
              src="images/plus-4.svg"
            />
          </div>
          <h5 className="add-new-prompt-label">Add New Prompt</h5>
        </div>
      </div>
    </div>
  );
};

export default Promptgallerycontentsbox;
