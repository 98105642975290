import { createSlice } from '@reduxjs/toolkit';

export const mentorsInitialState = {
  mentor: null,
  llms: null,
  selectedLLM: null,
  mentors: null,
  featured: null,
  edit: {
    mentor: null,
    activeTab: null,
    llmProvider: '',
  },
  initialSetup: false,
  dataset: {
    type: null,
  },
};

export const mentorSlice = createSlice({
  name: 'mentors',
  initialState: mentorsInitialState,
  reducers: {
    reset: () => mentorsInitialState,
    mentorsUpdated: (state, action) => {
      state.mentors = action.payload;
    },
    editMentorUpdated: (state, action) => {
      state.edit.mentor = action.payload;
    },
    editMentorLLMProviderUpdated: (state, action) => {
      state.edit.llmProvider = action.payload;
    },
    editMentorActiveTab: (state, action) => {
      state.edit.activeTab = action.payload;
    },
    datasetUpdated: (state, action) => {
      state.dataset = action.payload;
    },
    initialSetupUpdated: (state, action) => {
      state.initialSetup = action.payload;
    },
    featuredMentorsUpdated: (state, action) => {
      state.featured = action.payload;
    },
    currentMentorUpdated: (state, action) => {
      state.mentor = action.payload;
    },
    llmsUpdated: (state, action) => {
      state.llms = action.payload;
    },
    selectedLLMUpdated: (state, action) => {
      state.selectedLLM = action.payload;
    },
  },
});

export const mentorActions = mentorSlice.actions;
export default mentorSlice.reducer;
