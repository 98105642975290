import React from "react";
import "./Chathistorycontent.css";


const Chathistorycontent = props => {
    
    return (
<div className="w-layout-vflex history-element-container" map="" map-value="chat">
<div className="history-timeline">{props.timeframe}</div>
<div className="w-layout-hflex history-block greyish-bg-in-darkmode" data-w-id="732db3f5-936a-7c07-bf69-1513ba84539a">
<div className="history-left-side">
<div className="history-element-title">{props.title}</div>
</div>
<div className="history-right-side">
<div className="history-time">{props.date}</div>
<div className="w-layout-hflex history-action-btn-block">
<div className="history-action-btn" prop-events-value-onclick="handleChatEditClick"><img alt="" className="history-action-img" loading="lazy" src="images/edit.svg"/></div>
<div className="history-action-btn" prop-events-value-onclick="handleChatExportClick"><img alt="" className="history-action-img" loading="lazy" src="images/upload.svg"/></div>
<div className="history-action-btn" prop-events-value-onclick="handleChatRemoveClick"><img alt="" className="history-action-img" loading="lazy" src="images/trash-2.svg"/></div>
</div>
</div>
</div>
</div>
)}

export default Chathistorycontent;