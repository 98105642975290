import React from "react";
import "./Helpmodalbox.css";


const Helpmodalbox = props => {
    
    return (
<div className="modal help-modal">
<div className="w-layout-vflex modal-container help-modal-container help-container">
<div className="w-layout-hflex modal-header help-modal-header">
<h3 className="modal-header-title">Help</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body help-modal-body">
<div className="w-layout-vflex flex-block-13">
<div>Popular Resources</div>
</div>
<div className="w-layout-hflex help-element">
<div className="help-el-icon-container"><img alt="" className="help-el-icon-img" loading="lazy" src="images/file-text-3.svg"/></div>
<div className="help-el-description">Use IBL AI</div>
</div>
<div className="w-layout-hflex help-element">
<div className="help-el-icon-container"><img alt="" className="help-el-icon-img" loading="lazy" src="images/file-text-3.svg"/></div>
<div className="help-el-description">Where can you use IBL AI</div>
</div>
<div className="w-layout-hflex help-element">
<div className="help-el-icon-container"><img alt="" className="help-el-icon-img" loading="lazy" src="images/file-text-3.svg"/></div>
<div className="help-el-description">Sign in to IBL AI</div>
</div>
<div className="w-layout-hflex help-element">
<div className="help-el-icon-container"><img alt="" className="help-el-icon-img" loading="lazy" src="images/file-text-3.svg"/></div>
<div className="help-el-description">Manage &amp; delete your IBL AI Activity</div>
</div>
<div className="w-layout-hflex help-element">
<div className="help-el-icon-container"><img alt="" className="help-el-icon-img" loading="lazy" src="images/file-text-3.svg"/></div>
<div className="help-el-description">IBL AI Privacy Notice</div>
</div>
<div className="help-search-input-block w-form">
<form className="help-search-form" data-name="Email Form" data-wf-element-id="3f69cca6-0506-dab3-0ea6-dad18e6db4e0" data-wf-page-id="650305782bc61751e5765214" id="email-form" method="get" name="email-form"><input className="help-search-input w-input" data-name="Name 2" id="name-2" maxLength={256} name="name-2" placeholder="Search Help" type="text"/><img alt="" className="image-25" loading="lazy" src="images/search-svgrepo-com.svg"/></form>
<div className="w-form-done">
<div>Thank you! Your submission has been received!</div>
</div>
<div className="w-form-fail">
<div>Oops! Something went wrong while submitting the form.</div>
</div>
</div>
<div className="help-seperator-line"></div>
<div className="w-layout-hflex help-element help-element-report-problem">
<div className="help-el-icon-container help-el-report-problem"><img alt="" className="help-el-icon-img" loading="lazy" src="images/info-message-svgrepo-com-blueish.svg"/></div>
<div className="help-el-description help-report-problem">Report a problem</div>
</div>
</div>
</div>
</div>
)}

export default Helpmodalbox;