"use strict";
/**
 * @namespace ibledxusers
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.patchUserEmail = exports.patchUserFullname = exports.getUsersInfo = exports.postUserProfileImage = exports.postUserValidation = exports.getPublicDataByUsername = exports.forgotPassword = exports.postUsersManage = exports.postUsersManageMetadata = exports.getUsersManageMetadata = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Get metadata of user
 * @function getUsersManageMetadata
 * @memberof ibledxusers
 *
 * @see https://github.com/ibleducation/ibl-edx-user-management-api/blob/master/USAGE.md#user-metadata
 *
 * @param {Object} data
 * @param {String} [data.username] User's username
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Get metadata of user with username "example"</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.getUsersManageMetadata(
 *    {username: "example"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function getUsersManageMetadata(data, successCb, errorCb) {
    http.get(config_1.default.IBL_EDX.GET_USER_METADATA, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.getUsersManageMetadata = getUsersManageMetadata;
/**
 * Post metadata of user
 * @function postUsersManageMetadata
 * @memberof ibledxusers
 *
 * @see https://github.com/ibleducation/ibl-edx-user-management-api/blob/master/USAGE.md#user-metadata-update
 *
 * @param {Object} data
 * @param {String} [data.username] User's username
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Post metadata of user with username "example"</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.postUsersManageMetadata(
 *    {username: "example", "name": "Example User"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function postUsersManageMetadata(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_EDX.POST_USER_METADATA, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.postUsersManageMetadata = postUsersManageMetadata;
/**
 * User Creation/Update
 * @function postUsersManage
 * @memberof ibledxusers
 *
 * @see https://github.com/ibleducation/ibl-edx-user-management-api/blob/master/USAGE.md#user-creationupdate
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {String} data.email User's email
 * @param {String} [data.name] Full name of user
 * @param {String} [data.password] If creating a user and unsupplied, an unusable password will be set
 * @param {String} [data.provider] Supply the provider to link with.
 * @param {String} [data.tpa_uid] Supply the social auth value to match the user with (depends on backend). Defaults to the username supplied
 * @param {String} [data.is_staff=false] Sets global staff access
 * @param {String} [data.is_active=true] Set user active flag
 * @param {String} [data.update=false] Update user details if user exists
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Post data of user with username "example"</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.postUsersManage(
 *    {username: "example", "name": "Example User", "email": "user@example.com"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function postUsersManage(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_EDX.POST_USER_MANAGE, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.postUsersManage = postUsersManage;
function forgotPassword(callback = null) {
    http.post(config_1.default.IBL_EDX.FORGOT_PASSWORD, {}, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
exports.forgotPassword = forgotPassword;
function getPublicDataByUsername(data, successCb, errorCb) {
    http.get(config_1.default.IBL_EDX.GET_PUBLIC_DATA_BY_USERNAME, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.getPublicDataByUsername = getPublicDataByUsername;
/**
 * Post user validation
 * @function postUserValidation
 * @memberof ibledxusers
 *
 * @see https://github.com/iblai/ibl-dm-multi-services-app/blob/master/USAGE.md#user-validation
 *
 * @param {Object} data
 * @param {String} [data.username] Users's username
 * @param {String} [data.email] Users's email
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Post user validation</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.postUserValidation(
 *    {
 *      username: "johndoe",
 *      email: "johndoe@ibleducation.com"
 *    },
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function postUserValidation(data, successCb, errorCb) {
    http.post(config_1.default.IBL_EDX.POST_USER_VALIDATION, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    });
}
exports.postUserValidation = postUserValidation;
/**
 * Post user profile image
 * @function postUserProfileImage
 * @memberof ibledxusers
 *
 * @see https://github.com/iblai/ibl-edx-account-api?tab=readme-ov-file#upload-users-profile-image
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {File} data.file User's image file
 * @param {callback} successCb Callback function for successful request
 * @param {callback} errorCb Callback function for error in request
 *
 * @example <caption>Post user profile image using FormData</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.postUserProfileImage(
 *    {
 *      username: "johndoe",
 *      file : image ( binary file )
 *    },
 *    (data) => callback(data),
 *    (error) => callback(error)
 * )
 * ```
 */
function postUserProfileImage(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_EDX.UPLOAD_USERS_PROFILE_IMAGE(data.username), { file: data.file }, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.postUserProfileImage = postUserProfileImage;
/**
 * Get user's edx information
 * @function getUsersInfo
 * @memberof ibledxusers
 *
 *
 * @param {Object} data
 * @param {String} [data.username] Users's username
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Gets user's info</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.getUsersInfo(
 *    {
 *      username: "johndoe",
 *    },
 *    (data) => callback(data),
 *    (error) => callback(error)
 * )
 * ```
 */
function getUsersInfo(data, successCb = null, errorCb = null) {
    let url = config_1.default.IBL_EDX.GET_USER_DETAILS(data.username);
    http.get(url, null, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.getUsersInfo = getUsersInfo;
/**
 * Patches user's fullname
 * @function patchUserFullname
 * @memberof ibledxusers
 *
 *
 * @param {Object} data
 * @param {String} [data.username] Users's username
 * @param {String} [data.name] Users's fullname
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Patches user's fullname</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.patchUserFullname(
 *    {
 *      username: "johndoe",
 *      name: "Jane Doe"
 *    },
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function patchUserFullname(data, successCb, errorCb) {
    let url = config_1.default.IBL_EDX.PATCH_USER_DETAILS + data.username;
    let requestData = {
        name: data.name,
    };
    http.patch(url, requestData, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.patchUserFullname = patchUserFullname;
/**
 * Patches user's email
 * @function patchUserEmail
 * @memberof ibledxusers
 *
 * @param {Object} data
 * @param {String} [data.username] User's username
 * @param {String} [data.email] User's new email
 * @param {callback} successCb Callback function for successful request
 * @param {callback} errorCb Callback function for error in request
 *
 * @example <caption>Patches user's email</caption>
 * This usage:
 *
 * ```js
 * ibledxusers.patchUserEmail(
 *    {
 *      username: "johndoe",
 *      email: "janedoe@example.com"
 *    },
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function patchUserEmail(data, successCb = null, errorCb = null) {
    let url = config_1.default.IBL_EDX.PATCH_USER_DETAILS + data.username;
    let requestData = {
        email: data.email,
    };
    http.patch(url, requestData, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.patchUserEmail = patchUserEmail;
