import React, { useEffect, useState } from 'react';
import './Llminfomodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useLLMDetails from '../../hooks/llm/useLLMDetails';
import he from 'he';

const Llminfomodalbox = (props) => {
  const { handleModalCloseClick } = useTriggers();
  const llm = useSelector((state) => state.mentors.selectedLLM);
  const { activeTab, setActiveTab } = useLLMDetails();
  const [llmTabsMenuDisplayed, setLlmTabsMenuDisplayed] = useState(false);

  useEffect(() => {
    if (!(!!!llm?.use_cases && !!!llm?.documentation && !!!llm?.sdk_samples)) {
      setLlmTabsMenuDisplayed(true);
    }
  }, [llm]);

  return (
    <div className="modal llm-info-modal">
      <div className="w-layout-vflex modal-container prompt-gallery-container llm-info-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">LLMs</h3>
          <a className="default-btn prompt-add-btn w-button" href="#">
            + Add New Prompt
          </a>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
          <div className="prompts-container">
            <h3 className="llm-info-header-title">{llm?.model}</h3>
            <p className="llm-info-header-desc">{llm?.description}</p>
            <div className="w-layout-hflex llm-info-header-btn">
              <a
                className="button-primary w-button"
                data-w-id="51a1183e-f68c-7012-3b3a-230adba3b4f9"
                href="#"
              >
                Open Prompt Design
              </a>
            </div>
            <div
              className={`w-tabs ${!llmTabsMenuDisplayed && 'llm-tabs-menus-not-displayed'}`}
              data-current="Tab 1"
              data-duration-in={300}
              data-duration-out={100}
              data-easing="ease"
            >
              {!llmTabsMenuDisplayed ? (
                <></>
              ) : (
                <div className="tabs-menu w-tab-menu">
                  <a
                    className={`llm-detail-tab w-inline-block w-tab-link ${activeTab === 'overview' ? 'w--current' : ''}`}
                    data-w-tab="Tab 1"
                    onClick={() => {
                      setActiveTab('overview');
                    }}
                  >
                    <div>OVERVIEW</div>
                  </a>
                  {!!llm?.use_cases && (
                    <a
                      className={`llm-detail-tab w-inline-block w-tab-link ${activeTab === 'use-cases' ? 'w--current' : ''}`}
                      onClick={() => {
                        setActiveTab('use-cases');
                      }}
                      data-w-tab="Tab 2"
                    >
                      <div>USE CASES</div>
                    </a>
                  )}
                  {!!llm?.documentation && (
                    <a
                      className={`llm-detail-tab w-inline-block w-tab-link ${activeTab === 'documentation' ? 'w--current' : ''}`}
                      onClick={() => {
                        setActiveTab('documentation');
                      }}
                      data-w-tab="Tab 3"
                    >
                      <div>DOCUMENTATION</div>
                    </a>
                  )}
                  {!!llm?.sdk_samples && (
                    <a
                      className={`llm-detail-tab w-inline-block w-tab-link ${activeTab === 'sdk-samples' ? 'w--current' : ''}`}
                      onClick={() => {
                        setActiveTab('sdk-samples');
                      }}
                      data-w-tab="Tab 4"
                    >
                      <div>SDK &amp; SAMPLES</div>
                    </a>
                  )}
                </div>
              )}

              <div className="w-tab-content">
                {activeTab === 'overview' && (
                  <div
                    className="llm-tab-content w-tab-pane w--tab-active"
                    data-w-tab="Tab 1"
                  >
                    <div className="w-layout-hflex flex-block-58">
                      <div className="w-layout-vflex flex-block-60">
                        <div
                          className="llm-tab-conten-rich-text w-richtext"
                          dangerouslySetInnerHTML={{
                            __html: he.decode(llm?.overview),
                          }}
                        ></div>
                      </div>
                      <div className="w-layout-vflex llm-tab-right-side-content">
                        <h5>Resource IDs</h5>
                        {llm?.resource_ids?.map((item) => (
                          <div key={item} className="text-block-44">
                            {item}
                          </div>
                        ))}
                        <h5 className="heading-15">Tags</h5>
                        <div className="w-layout-vflex flex-block-57">
                          <h6 className="chat-export-details-title">TASK</h6>
                          <div className="w-layout-hflex llm-prompt-element-top-header-tag">
                            {llm?.tags?.tasks?.map((item) => (
                              <div
                                key={item}
                                className="llm-prompt-element-top-header-choice"
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-layout-vflex flex-block-57">
                          <h6 className="chat-export-details-title">
                            LANGUAGE
                          </h6>
                          <div className="w-layout-hflex llm-prompt-element-top-header-tag">
                            {llm?.tags?.languages?.map((item) => (
                              <div
                                key={item}
                                className="llm-prompt-element-top-header-choice"
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-layout-vflex flex-block-57">
                          <h6 className="chat-export-details-title">
                            SKILL LEVEL
                          </h6>
                          <div className="w-layout-hflex llm-prompt-element-top-header-tag">
                            {llm?.tags?.skill_levels?.map((item) => (
                              <div
                                key={item}
                                className="llm-prompt-element-top-header-choice"
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === 'use-cases' && (
                  <div
                    className="llm-tab-content w-tab-pane"
                    data-w-tab="Tab 2"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: llm?.use_cases }}
                    ></div>
                  </div>
                )}

                {activeTab === 'documentation' && (
                  <div
                    className="llm-tab-content w-tab-pane"
                    data-w-tab="Tab 2"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: llm?.documentation }}
                    ></div>
                  </div>
                )}

                {activeTab === 'sdk-samples' && (
                  <div
                    className="llm-tab-content w-tab-pane"
                    data-w-tab="Tab 2"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: llm?.sdk_samples }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Llminfomodalbox;
