import React from 'react';
import './CircularLoader.css'

const CircularLoader = ({focused=false}) => {
  return (
    <div className={`w-layout-hflex circular-loader ${!focused ? "hidden" : ""}`}>
      <div className="circular-loader-embed w-embed">
        <div className="lds-ripple-circle-loader">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default CircularLoader;