import useMentorSuggestedPrompts from './useMentorSuggestedPrompts';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useMentorPrompts() {
  const mentors = useSelector((state) => state.mentors);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const { loadSuggestedPrompts } = useMentorSuggestedPrompts();

  const prepareSuggestedPrompts = async () => {
    const data = await loadSuggestedPrompts(mentors?.edit?.mentor?.unique_id);
    if (data) {
      const prompts = data?.filter((item) => item.trim());
      setSuggestedPrompts(prompts);
    }
  };

  useEffect(() => {
    prepareSuggestedPrompts();
  }, [mentors?.edit?.mentor]);

  return { suggestedPrompts };
}
