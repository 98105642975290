import { useTriggers } from '../navigation';
import { useDispatch } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';

export default function useLLMItem(llm) {
  const { _handleUpdateModalStatus } = useTriggers();
  const dispatch = useDispatch();
  const handleLLMUpdateKeyBtnClick = () => {
    dispatch(mentorActions.editMentorLLMProviderUpdated(llm.name));
    _handleUpdateModalStatus('llm-set-api-key-modal', true);
  };

  const handleLLMViewBtnClick = () => {
    dispatch(mentorActions.selectedLLMUpdated(llm));
    _handleUpdateModalStatus('llm-info-modal', true);
  };

  return { handleLLMUpdateKeyBtnClick, handleLLMViewBtnClick };
}
