"use strict";
/**
 * @namespace ibldmresources
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserResourceCompletion = void 0;
/**
 * This callback type is called `successCb` and is called when an api request is successful.
 * @callback successCb
 * @param {Object} data Data is passed to the success callback function
 */
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Retrieves the resource completion for a user
 * @kind function
 * @name getUserResourceCompletion
 * @memberof ibldmresources
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {String} data.resource_id Resource ID
 * @param {callback} successCb
 *
 * @example <caption>Get Resource completion for a user</caption>
 * This usage:
 *
 * ```js
 * ibldmresources.getUserResourceCompletion(
 *   {
 *        username: "example",
 *        resource_id: "resource-v1:IBL+1+1",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
function getUserResourceCompletion(data, successCb = null) {
    http.get(config_1.default.IBL_DM.GET_USER_RESOURCE_COMPLETION, data, (data) => {
        if (successCb) {
            successCb(data);
        }
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.getUserResourceCompletion = getUserResourceCompletion;
