"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerUser = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const utils_1 = require("../utils");
const http = new http_1.default();
function registerUser(data, successCb = null, errorCb = null, params = {}) {
    http.post(config_1.default.REGISTRATION + (0, utils_1.buildQueryParams)(params), data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.registerUser = registerUser;
