import React from 'react';
import './Manageaccountmodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import useManageAccount from '../../hooks/user/userManageAccount';

const Manageaccountmodalbox = (props) => {
  const { handleModalCloseClick } = useTriggers();
  const {
    handleTenantChange,
    handleUserChange,
    user,
    tenant,
    submitAccountData,
    submitting,
  } = useManageAccount();
  return (
    <div className="modal manage-account-modal">
      <div className="w-layout-vflex modal-container export-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Manage Account</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body manage-account-modal-body">
          <div className="manage-account-form-block w-form">
            <form
              className="manage-account-form"
              data-name="Email Form 2"
              data-wf-element-id="7fe92682-aa47-c52e-5c20-6ffcd8b4314f"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-2"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block">
                <label className="form-input-label" htmlFor="name">
                  Organization name
                </label>
                <div className="form-input-desc">
                  Human-friendly label for your organization, shown in user
                  interfaces
                </div>
                <input
                  className="form-input organization-name-input w-input"
                  custom-value="Personal"
                  data-name="organisation-name"
                  id="organisation-name"
                  disabled
                  maxLength={256}
                  value={tenant.name}
                  onChange={handleTenantChange}
                  name="name"
                  placeholder=""
                  type="text"
                />
              </div>
              <div className="w-layout-vflex input-block">
                <label className="form-input-label" htmlFor="name-3">
                  Organization ID
                </label>
                <div className="form-input-desc">
                  Identifier for this organization sometimes used in API
                  requests
                </div>
                <input
                  className="form-input form-input-disabled organisation-id-input w-input"
                  data-name="Organisation Name 2"
                  id="organisation-name-2"
                  maxLength={256}
                  disabled
                  value={tenant.key}
                  onChange={handleTenantChange}
                  name="key"
                  placeholder="Org key"
                  type="text"
                />
              </div>
              <div className="w-layout-vflex input-block">
                <label className="form-input-label" htmlFor="name-3">
                  Full Name
                </label>
                <div className="form-input-desc">Your full name</div>
                <input
                  className="form-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 3"
                  id="organisation-name-3"
                  maxLength={256}
                  onChange={handleUserChange}
                  value={user.user_fullname}
                  name="user_fullname"
                  placeholder=""
                  type="text"
                />
              </div>
              <div className="w-layout-vflex input-block">
                <label className="form-input-label" htmlFor="name-3">
                  Username
                </label>
                <div className="form-input-desc">Must be unique</div>
                <input
                  className="form-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 3"
                  id="organisation-name-3"
                  maxLength={256}
                  disabled
                  value={user.user_nicename}
                  name="organisation-name-3"
                  placeholder=""
                  type="text"
                />
              </div>
              <div className="w-layout-vflex input-block">
                <label className="form-input-label" htmlFor="name-3">
                  Email
                </label>
                <div className="form-input-desc">
                  A confirmation link would be sent to you immediately
                </div>
                <input
                  className="form-input w-input"
                  custom-value="Personal"
                  disabled
                  data-name="Organisation Name 3"
                  id="organisation-name-3"
                  value={user?.user_email}
                  maxLength={256}
                  name="user_email"
                  placeholder="hi@example.com"
                  type="email"
                />
              </div>
              <div className="w-layout-vflex input-block hidden">
                <label className="form-input-label" htmlFor="name-4">
                  LLM Provider
                </label>
                <div className="form-input-desc">Select your LLM Provider</div>
                <select
                  className="llm-provider-select w-select"
                  data-name="Field 3"
                  id="field-3"
                  name="field-3"
                >
                  <option value="">Choose...</option>
                  <option value="openai">OpenAI</option>
                  <option value="google">Google</option>
                </select>
              </div>
              <div className="w-layout-vflex input-block hidden">
                <label className="form-input-label" htmlFor="name-4">
                  LLM API Key
                </label>
                <div className="form-input-desc">Your LLM Provider API Key</div>
                <input
                  className="form-input llm-api-key-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 3"
                  id="organisation-name-3"
                  maxLength={256}
                  name="organisation-name-3"
                  placeholder="X47owag1DNIirxT9"
                  type="text"
                />
              </div>
              <a
                className="default-btn form-submit-btn w-button"
                onClick={submitAccountData}
                href="#"
              >
                {submitting ? <span>Saving...</span> : <span>Save</span>}
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manageaccountmodalbox;
