"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.close = exports.removeAll = exports.remove = exports.listen = exports.sendPrompt = exports.connect = exports.init = void 0;
const $ = require("jquery");
let webSocket = null;
function init(path = '/ws/langflow/') {
    webSocket = $.simpleWebSocket({
        url: window.BASE_WS_URL + path,
        dataType: 'text',
    });
}
exports.init = init;
function connect(websocketData) {
    webSocket.send(JSON.stringify(websocketData));
}
exports.connect = connect;
function sendPrompt(prompt) {
    webSocket.send(prompt);
}
exports.sendPrompt = sendPrompt;
function listen(functioncB) {
    webSocket.listen(functioncB);
}
exports.listen = listen;
function remove(listenerCb) {
    webSocket.remove(listenerCb);
}
exports.remove = remove;
function removeAll() { }
exports.removeAll = removeAll;
function close() {
    webSocket.close();
}
exports.close = close;
