import Userpromptbox from '../../../components/Userpromptbox/Userpromptbox';
import React from 'react';
import { getFileExtension } from '../../../utils/shared';

const DocumentUploaded = ({ content }) => (
  <div className="file-prompt-template">
    <div className="user-prompt-text-container user-file-prompt-container">
      <div className="user-prompt-text-block file-block">
        <div className="w-layout-hflex file-prompt-block">
          <div className="w-layout-hflex file-prompt-icon-block">
            <img
              src="images/file-text-1.svg"
              loading="lazy"
              alt=""
              className="file-prompt-icon"
            />
          </div>
          <div className="w-layout-vflex file-prompt-uploaded-info-block">
            <h6 className="file-upload-name-block uploaded">{content}</h6>
            <div className="file-upload-extension-block uploaded">
              {getFileExtension(content)}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function UserQuery({ message }) {
  return (
    <div className="user-prompt-template">
      {message.contentType === 'file' ? (
        <DocumentUploaded content={message.content} />
      ) : (
        <Userpromptbox message={message} />
      )}
    </div>
  );
}
