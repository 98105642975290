import React from "react";
import "./Createsecretkeymodalbox.css";


const Createsecretkeymodalbox = props => {
    
    return (
<div className="modal create-secret-key-modal">
<div className="w-layout-vflex modal-container create-secret-key-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">Create New Secret Key</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body create-secret-key-modal-body">
<div className="secret-key-form-block w-form">
<form className="manage-account-form secret-key-form" data-name="Email Form 2" data-wf-element-id="ac494637-d09c-3688-b1d4-e5c96ecaaa9a" data-wf-page-id="650305782bc61751e5765214" id="email-form-2" method="get" name="email-form-2">
<div className="w-layout-vflex input-block">
<div className="w-layout-hflex flex-block-19"><label className="form-input-label" htmlFor="name-3">Hard Limit</label>
<div className="form-input-desc secret-key-form-input-desc">Optional</div>
</div><input className="form-input secret-key-form-input w-input" custom-value="Personal" data-name="Organisation Name 4" id="organisation-name-4" maxLength={256} name="organisation-name-4" placeholder="My Test Key" type="text"/>
</div>
<div className="w-layout-hflex flex-block-20">
<a className="default-btn form-submit-btn form-cancel-btn w-button" data-w-id="ac494637-d09c-3688-b1d4-e5c96ecaaaa7" href="#">Cancel</a>
<a className="default-btn form-submit-btn w-button" data-w-id="fa301b61-b17e-2fcb-25e2-efa67e209d6c" href="#">Save</a>
</div>
</form>
<div className="w-form-done">
<div>Thank you! Your submission has been received!</div>
</div>
<div className="w-form-fail">
<div>Oops! Something went wrong while submitting the form.</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Createsecretkeymodalbox;