"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPathway = exports.getPathway = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
function getPathway(data, successCB = null, errorCB = null) {
    http.get(config_1.default.IBL_DM.GET_PATHWAY, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
exports.getPathway = getPathway;
function createPathway(data, successCB = null, errorCB = null) {
    http.post(config_1.default.IBL_DM.CREATE_PATHWAY, data, (data) => {
        successCB && successCB(data);
    }, (error) => {
        errorCB && errorCB(error);
    }, true, 'IBL_DM_URL');
}
exports.createPathway = createPathway;
