import useApi from '../base/useApi';
import { useSelector } from 'react-redux';

export default function useMentorSuggestedPrompts() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const loadSuggestedPrompts = async (mentor) => {
    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${mentor}/suggested-prompts/`;
    const response = await api.get(url);
    if (response?.data) {
      return response?.data?.prompts;
    }
  };

  return { loadSuggestedPrompts };
}
