"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserPrograms = void 0;
const config_1 = require("../config");
const http_1 = require("../http");
const http = new http_1.default();
function getUserPrograms(data, callback = null) {
    http.get(config_1.default.IBL_DM.GET_USER_PROGRAMS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.getUserPrograms = getUserPrograms;
