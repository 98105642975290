import Suggestedpromptbox from '../../../components/Suggestedpromptbox/Suggestedpromptbox';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DocumentUploadProgress from './document-upload-progress';
import useDocumentUpload from '../../../hooks/chat-room/useDocumentUpload';
import DocumentUpload from './document-upload';
import { useTriggers } from '../../../hooks/navigation';
import useAnonymousMode from '../../../hooks/iframe/useAnonymousMode';

export default function ChatRoomForm({
  handleUserPromptFormSubmit,
  handlePromptChange,
  handleStopRespondingBtnClick,
  streaming,
  generating,
}) {
  const chat = useSelector((state) => state.chat.data);
  const { handlePromptFileUploadBtnClick, file, uploading, handleFileChange } =
    useDocumentUpload();
  const { handleAIAudioCallBtnClick } = useTriggers();
  const anonymous = useAnonymousMode();

  return (
    <div className="bottom-area">
      <div className="form-block w-form">
        <form
          className="form"
          data-name="user-prompt"
          data-wf-element-id="2cfcedfe-aeba-88e5-d900-4a8156964a67"
          data-wf-page-id="650305782bc61751e5765214"
          id="wf-form-user-prompt"
          method="post"
          name="wf-form-user-prompt"
          action="#"
          prop-events-names="onSubmit"
          onSubmit={handleUserPromptFormSubmit}
          prop-events-value-onsubmit="handleUserPromptFormSubmit"
        >
          <div
            className="w-layout-hflex new-chat-block whiten-border-in-dark-mode all-whiten-in-dark-mode"
            data-w-id="9e41a1e7-5b47-4410-733f-70324ffe5f9d"
          >
            <img
              alt=""
              className="image-34"
              loading="lazy"
              src="images/plus-4.svg"
            />
            <div className="tooltip tooltip-new-chat">New Chat</div>
          </div>
          <div className="input-container">
            {(streaming || generating) && (
              <a
                className="stop-responding-btn primary-color-in-dark-mode primary-border-in-dark-mode"
                href="#"
                prop-events-names="onClick"
                onClick={handleStopRespondingBtnClick}
                prop-events-value-onclick="handleStopRespondingBtnClick"
              >
                Stop Responding
              </a>
            )}
            {uploading && <DocumentUploadProgress file={file} />}

            <div className="user-input-field-block">
              <input
                className="user-prompt-input-field black-bg-dark-mode whiten-border-in-dark-mode whiten-in-dark-mode w-input"
                data-name="Name"
                id="user-prompt"
                name="prompt"
                autoFocus
                value={chat.prompt}
                disabled={generating || streaming}
                onChange={handlePromptChange}
                placeholder="Enter a Prompt Here"
                type="text"
                style={{ paddingRight: '50px' }}
              />
              <div className="div-block-8" style={{ marginRight: '6px' }}>
                {!anonymous && (
                  <DocumentUpload
                    handlePromptFileUploadBtnClick={
                      handlePromptFileUploadBtnClick
                    }
                    handleFileChange={handleFileChange}
                  />
                )}

                {process.env.REACT_APP_IBL_ENABLE_AUDIO_VOICE_CHAT ===
                  'true' && (
                  <div
                    className="user-input-actions-container greyish-bg-in-darkmode user-input-microphone-btn has-custom-tooltip"
                    data-w-id="c4c241a8-c39d-44d4-4bf4-1cd95b8c7c6c"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleMicrophoneBtnClick"
                    style={{ display: 'none' }}
                  >
                    <img
                      alt=""
                      className="image-6 whiten-in-dark-mode"
                      loading="lazy"
                      src="images/microphone-svgrepo-com.svg"
                    />
                    <div className="tooltip tooltip-microphone">
                      Use the microphone
                    </div>
                  </div>
                )}

                {process.env.REACT_APP_IBL_ENABLE_WEBRTC_CHAT === 'true' && (
                  <div
                    className="user-input-actions-container greyish-bg-in-darkmode user-input-audio-chat-btn has-custom-tooltip"
                    data-w-id="ac150336-cb25-4850-e62d-80496475ceab"
                    prop-events-names="onClick"
                    onClick={handleAIAudioCallBtnClick}
                    prop-events-value-onclick="handleAIAudioCallBtnClick"
                  >
                    <img
                      alt=""
                      className="image-6 whiten-in-dark-mode ai-audio-chat-icon"
                      loading="lazy"
                      src="images/phone-calling-svgrepo-com.svg"
                    />
                    <div className="tooltip tooltip-microphone">
                      Make AI Audio Chat
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-layout-hflex flex-block-24 suggested-prompt-box-container-custom">
              <div className="w-layout-hflex flex-block-25">
                <div className="w-layout-hflex mentor-footer-action-block">
                  <Suggestedpromptbox />
                  <div className="w-layout-hflex mentor-footer-btn mentor-footer-action-btn-focused mentor-file-upload-btn">
                    <img
                      alt=""
                      className="mentor-footer-action-icon"
                      loading="lazy"
                      src="https://uploads-ssl.webflow.com/64f8985bb2f5586690877be7/64fa074f61fd5ecb6939a715_paperclip%20(1).svg"
                    />
                  </div>
                </div>
              </div>
              <div className="footer-disclaimer whiten-in-dark-mode">
                Disclaimer: IBL AI Mentor may display inaccurate information
                that doesn’t represent our views.
              </div>
              <div className="w-embed">
                <input className="input-file-upload" type="file" />
                <style
                  dangerouslySetInnerHTML={{
                    __html: `
.af-view .input-file-upload {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
 `,
                  }}
                />
              </div>
            </div>
          </div>
          <input
            className="submit-button w-button"
            id="user-prompt-submit-btn"
            data-wait="Please wait..."
            defaultValue=""
            type="submit"
          />
          <div
            className="input-submit-container"
            prop-events-names="onClick"
            onClick={handleUserPromptFormSubmit}
            prop-events-value-onclick="handleUserPromptFormSubmit"
          >
            <img
              alt=""
              className={`input-submit-icon whiten-in-dark-mode ${chat?.prompt ? 'input-submit-icon-enabled' : ''}`}
              loading="lazy"
              src="images/send-svgrepo-com.svg"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
