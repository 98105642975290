import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { toast } from 'react-toastify';

export default function useSelectMentorLLM(llm) {
  const auth = useSelector((state) => state.auth.data);
  const editMentor = useSelector((state) => state.mentors.edit);
  const mentors = useSelector((state) => state.mentors.mentors);
  const dispatch = useDispatch();

  const api = useApi();

  const handleUpdateLocalMentors = (data) => {
    const updatedList = mentors?.results?.map((item) => {
      if (item.name === data.name) {
        return data;
      }
      return item;
    });
    dispatch(
      mentorActions.mentorsUpdated({ ...mentors, results: updatedList })
    );
  };

  const handleLLMSelected = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${editMentor?.mentor?.name}/settings/`;
    const payload = {
      llm_provider: llm.name,
      llm_name: llm.model,
    };

    const response = await api.put(url, payload);
    if (response?.data) {
      const updatedMentor = {
        ...editMentor?.mentor,
        llm_name: response?.data?.llm_name,
        llm_provider: response?.data?.llm_provider,
        settings: response?.data,
      };

      dispatch(mentorActions.editMentorUpdated(updatedMentor));
      handleUpdateLocalMentors(updatedMentor);
    }
  };

  const llmIsSelected = () => {
    return (
      editMentor?.mentor?.llm_name === llm.model ||
      editMentor?.mentor?.settings?.llm_name === llm.model
    );
  };

  return { handleLLMSelected, llmIsSelected };
}
