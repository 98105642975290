import { useEffect, useState } from 'react';
import { authActions } from '../../../lib/redux/auth/slice';
import { useDispatch } from 'react-redux';
import Chat from './share-chat';
import PageLoader from '../../loader/page-loader';

export default function ShareChat() {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const authData = { ...localStorage };
    const authState = {};
    if (authData?.userData) {
      authState['tenants'] = JSON.parse(authData.tenants);
      authState['tenant'] = JSON.parse(authData.current_tenant);
      authState['user'] = JSON.parse(authData.userData);

      dispatch(authActions.authUpdated(authState));
      setReady(true);
    }
  }, []);

  return <>{ready ? <Chat /> : <PageLoader />}</>;
}
