import Mobilesettingsmenudropdownbox from '../Mobilesettingsmenudropdownbox/Mobilesettingsmenudropdownbox';
import React from 'react';
import './Helpmenudropdownbox.css';

const Helpmenudropdownbox = (props) => {
  return (
    <nav className="header-help-dropdown-container help-dropdown w-dropdown-list">
      <div className="w-layout-vflex user-menu-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode whiten-border-in-dark-mode help-user-menu-dropdown-block">
        <a
          className="user-menu-link greyish-bg-in-darkmode display-only-on-mobile w-inline-block"
          data-w-id="a7127da2-4676-8cd5-ba09-f945294c0edb"
          href="#"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="images/terminal-square-1.svg"
          />
          <div className="user-menu-label">Prompts Gallery</div>
        </a>
        <div
          className="header-settings-dropdown submenu-with-children-dropdown w-dropdown"
          data-delay={0}
          data-hover="false"
        >
          <div
            className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode submenu-with-children-dropdown-element w-dropdown-toggle"
            prop-events-value-onclick="handleMobileSettingsBtnClick"
          >
            <a
              className="user-menu-link greyish-bg-in-darkmode w-inline-block"
              href="#"
            >
              <img
                alt=""
                className="user-menu-icon"
                loading="lazy"
                src="images/cog.svg"
              />
              <div className="user-menu-label">Settings</div>
            </a>
          </div>
          <Mobilesettingsmenudropdownbox></Mobilesettingsmenudropdownbox>
        </div>
        <a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          href="#"
          prop-events-value-onclick="handleUpdateBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="images/note-svgrepo-com.svg"
          />
          <div className="user-menu-label">Updates</div>
        </a>
        <a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          data-w-id="173f0889-c967-79aa-41d4-5e029a3d5141"
          href="#"
          prop-events-value-onclick="handleHelpBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="images/info-message-svgrepo-com.svg"
          />
          <div className="user-menu-label">Help</div>
        </a>
        <a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          href="#"
          prop-events-value-onclick="handleFAQBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="images/question-answer-svgrepo-com.svg"
          />
          <div className="user-menu-label">FAQ</div>
        </a>
        <a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          href="#"
          prop-events-value-onclick="handlePrivacyHubBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="images/privacy-tips-svgrepo-com.svg"
          />
          <div className="user-menu-label">Privacy Hub</div>
        </a>
      </div>
    </nav>
  );
};

export default Helpmenudropdownbox;
