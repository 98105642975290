import React from "react";
import "./Renamechatmodalbox.css";


const Renamechatmodalbox = props => {
    
    return (
<div className="modal rename-history-modal">
<div className="w-layout-vflex modal-container create-secret-key-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">Rename Chat</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body create-secret-key-modal-body">
<div className="secret-key-form-block w-form">
<form className="manage-account-form secret-key-form" data-name="Email Form 2" data-wf-element-id="c4d77d71-179e-766c-a7b7-21ec90db4cb7" data-wf-page-id="650305782bc61751e5765214" id="email-form-2" method="get" name="email-form-2">
<div className="w-layout-vflex input-block"><input className="form-input secret-key-form-input rename-chat-form-input light-black-bg-dark-mode whiten-in-dark-mode w-input" custom-value="Personal" data-name="Organisation Name 4" id="organisation-name-4" maxLength={256} name="organisation-name-4" placeholder="Chat Name" type="text"/></div>
<div className="w-layout-hflex flex-block-20">
<a className="default-btn form-submit-btn form-cancel-btn w-button" data-w-id="c4d77d71-179e-766c-a7b7-21ec90db4cc0" href="#">Cancel</a>
<a className="default-btn form-submit-btn history-modal-submit-btn w-button" data-w-id="c4d77d71-179e-766c-a7b7-21ec90db4cc2" href="#">Rename</a>
</div>
</form>
<div className="w-form-done">
<div>Thank you! Your submission has been received!</div>
</div>
<div className="w-form-fail">
<div>Oops! Something went wrong while submitting the form.</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Renamechatmodalbox;