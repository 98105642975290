import { useState } from 'react';
import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { toast } from 'react-toastify';
import { useTriggers } from '../navigation';
import useUpdateLocalLLMCredStatus from './useUpdateLocalLLMCredStatus';

export default function useSetLLMCred() {
  const [key, setKey] = useState('');
  const [saving, setSaving] = useState(false);
  const auth = useSelector((state) => state.auth.data);
  const edit = useSelector((state) => state.mentors.edit);
  const api = useApi();
  const { _handleUpdateModalStatus } = useTriggers();
  const { handleUpdateLLMCredItemStatus } = useUpdateLocalLLMCredStatus();
  const [fieldRequired, setFieldRequired] = useState(false);

  const handleKeyChange = (event) => {
    setKey(event.target.value);
  };

  const handleSaveKey = async (event) => {
    event.preventDefault();
    if (!!!key) {
      setFieldRequired(true);
      return;
    }
    setFieldRequired(false);
    const url = `/api/ai-account/orgs/${auth?.tenant?.key}/credential/`;
    const data = {
      name: edit?.llmProvider,
      value: {
        key,
      },
      platform: auth?.tenant?.key,
    };
    setSaving(true);
    const response = await api.post(url, data);
    if (response?.data) {
      handleUpdateLLMCredItemStatus(edit?.llmProvider);
      toast.success(
        'Successfully saved key for this LLM. You can now start chatting'
      );
      setKey('');
      _handleUpdateModalStatus('llm-set-api-key-modal', false);
    } else {
      toast.error('Unable to save the LLM Key');
    }
    setSaving(false);
  };

  return { saving, handleKeyChange, handleSaveKey, key, fieldRequired };
}
