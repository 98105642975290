"use strict";
/**
 * @namespace ibldmauth
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToken = void 0;
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Get a user token for dm
 * @function getToken
 * @memberof iblwebauth
 *
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Login user to ibl web</caption>
 * This usage:
 *
 * ```js
 * ibldmauth.getToken(
 * )
 * ```
 */
function getToken(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_DM.GET_TOKEN, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.getToken = getToken;
