import React, { useEffect } from 'react';
import ChatRoomForm from './bottom-area/chat-room-form';
import ChatMessages from './upper-area/chat-messages';
import useSetChatSession from '../../hooks/chat-room/useSetChatSession';
import { useChatRoom } from '../../hooks/chat-room';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';

export default function ChatRoom() {
  const chat = useSelector((state) => state.chat.data);
  const { createChatSession } = useSetChatSession();
  const { sidebar } = useSelector((state) => state.navigation.data);
  const dispatch = useDispatch();
  const {
    generating,
    streaming,
    handleUserPromptFormSubmit,
    handlePromptChange,
    handleStopRespondingBtnClick,
  } = useChatRoom();

  useEffect(() => {
    dispatch(chatActions.generatingUpdated(generating));
  }, [generating, dispatch]);

  useEffect(() => {
    dispatch(chatActions.streamingUpdated(streaming));
  }, [streaming, dispatch]);

  useEffect(() => {
    createChatSession();
  }, [chat.sequence]);

  return (
    <div
      className={`body-right-side light-grey-bg-dark-mode ${!sidebar ? 'menu-toggling' : ''}`}
    >
      <ChatMessages generating={generating} streaming={streaming} />
      <ChatRoomForm
        handlePromptChange={handlePromptChange}
        handleUserPromptFormSubmit={handleUserPromptFormSubmit}
        handleStopRespondingBtnClick={handleStopRespondingBtnClick}
        generating={generating}
        streaming={streaming}
      />
    </div>
  );
}
