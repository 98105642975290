import { useState, useEffect } from 'react';
import { redirectToAuth } from '../../../utils/auth';

export default function useAxdApi(
  endpoint,
  method = 'GET',
  payload = {},
  isForm = false,
  start = true
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const url = `${process.env.REACT_APP_IBL_AXD_URL}${endpoint}`;

  const makeRequest = async () => {
    const headers = {
      Authorization: `Token ${localStorage.getItem('axd_token')}`,
    };
    let body = payload;

    if (!isForm && method !== 'GET') {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(body);
    }
    const options = {
      method,
      headers,
    };

    if (method !== 'GET') {
      options['body'] = body;
    }

    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        setData(await response.json());
      } else {
        if (response.status === 401) {
          redirectToAuth(true);
        }
        setError(await response.text());
      }
    } catch (e) {
      setError(e.toString());
    }
    setLoading(false);
  };

  useEffect(() => {
    if (start && !loading) {
      makeRequest();
    }
  }, [start, url]);

  return { loading, data, error };
}
