import React from 'react';
import useApi from '../../../hooks/api/base/useApi';
import { useSelector } from 'react-redux';

export default function DatasetItem({ item }) {
  const [isTrained, setIsTrained] = React.useState(Boolean(item?.is_trained));
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const handleToggleTrainDataset = async (event) => {
    const url = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.tenant?.key}/documents/${item?.id}/`;
    const data = {
      train: event.target.checked,
      pathway: item.pathway,
      url: item.url,
    };
    const response = await api.put(url, data);
    if (response?.data) {
      setIsTrained(response?.data?.is_trained);
    }
  };

  return (
    <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
      <div className="table-block table-block-very-big">
        {item.document_type.toLowerCase() === 'url' ? (
          <a
            href={`${item.url}&username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`}
            target="_blank"
            className="table-text-link underline-on-hover w-inline-block"
          >
            <div className="table-text text-inline">
              {item.document_name || item.url}
            </div>
          </a>
        ) : (
          <div className="table-text text-inline">
            {item.document_name || item.url}
          </div>
        )}
      </div>
      <div className="table-block table-block-medium">
        <div className="table-text">{item.document_type}</div>
      </div>
      <div className="table-block table-block-big bold-in-dark-mode">
        <div className="table-text">
          {item.tokens}
          <br />
        </div>
      </div>
      <div className="table-block table-block-large">
        <span className="text-inline">{item.url}</span>
      </div>
      <div className="table-block table-block-small">
        <div className="user-row-switcher-embed w-embed">
          <label className="user-switcher">
            <input
              onChange={handleToggleTrainDataset}
              type="checkbox"
              checked={isTrained}
            />
            <span className="user-switcher-slider round"></span>
          </label>
        </div>
      </div>
    </div>
  );
}
