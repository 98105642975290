import React from "react";
import "./Integrationapikeymodalbox.css";


const Integrationapikeymodalbox = props => {
    
    return (
<div className="modal integration-modal">
<div className="w-layout-vflex modal-container integration-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">API Keys</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body integration-modal-body">
<div className="integration-text">Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.</div>
<div className="integration-text">Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, IBL may also automatically rotate any API key that we've found has leaked publicly.</div>
<div className="w-layout-vflex table-container">
<div className="w-layout-hflex table-header api-key-table-header">
<div className="api-name-block">
<h5 className="table-title">NAME</h5>
</div>
<div className="api-key-block">
<h5 className="table-title">KEY</h5>
</div>
<div className="api-created-block">
<h5 className="table-title">CREATED</h5>
</div>
<div className="api-last-used-block">
<h5 className="table-title">LAST USED</h5>
</div>
<div className="api-action-block"></div>
</div>
<div className="w-layout-hflex table-body-row">
<div className="api-name-block">
<div className="table-text">Secret key</div>
</div>
<div className="api-key-block">
<div className="table-text">sk-.....jhj</div>
</div>
<div className="api-created-block">
<div className="table-text">Jul 19, 2023</div>
</div>
<div className="api-last-used-block">
<div className="table-text">Jul 20, 2023</div>
</div>
<div className="api-action-block">
<div className="w-layout-hflex api-action-container">
<div className="api-action-icon-container greyish-bg-in-darkmode"><img alt="" className="api-action-icon whiten-in-dark-mode" loading="lazy" src="images/edit.svg"/></div>
<div className="api-action-icon-container greyish-bg-in-darkmode"><img alt="" className="api-action-icon whiten-in-dark-mode" loading="lazy" src="images/trash-2.svg"/></div>
</div>
</div>
</div>
<div className="w-layout-hflex table-body-row">
<div className="api-name-block">
<div className="table-text">Secret key</div>
</div>
<div className="api-key-block">
<div className="table-text">sk-.....jhj</div>
</div>
<div className="api-created-block">
<div className="table-text">Jul 19, 2023</div>
</div>
<div className="api-last-used-block">
<div className="table-text">Jul 20, 2023</div>
</div>
<div className="api-action-block">
<div className="w-layout-hflex api-action-container">
<div className="api-action-icon-container greyish-bg-in-darkmode"><img alt="" className="api-action-icon whiten-in-dark-mode" loading="lazy" src="images/edit.svg"/></div>
<div className="api-action-icon-container greyish-bg-in-darkmode"><img alt="" className="api-action-icon whiten-in-dark-mode" loading="lazy" src="images/trash-2.svg"/></div>
</div>
</div>
</div>
<div className="w-layout-hflex table-body-row">
<div className="api-name-block">
<div className="table-text">Secret key</div>
</div>
<div className="api-key-block">
<div className="table-text">sk-.....jhj</div>
</div>
<div className="api-created-block">
<div className="table-text">Jul 19, 2023</div>
</div>
<div className="api-last-used-block">
<div className="table-text">Jul 20, 2023</div>
</div>
<div className="api-action-block">
<div className="w-layout-hflex api-action-container">
<div className="api-action-icon-container greyish-bg-in-darkmode"><img alt="" className="api-action-icon whiten-in-dark-mode" loading="lazy" src="images/edit.svg"/></div>
<div className="api-action-icon-container greyish-bg-in-darkmode"><img alt="" className="api-action-icon whiten-in-dark-mode" loading="lazy" src="images/trash-2.svg"/></div>
</div>
</div>
</div>
</div>
<a className="default-btn w-button" data-w-id="66836f4f-d2f1-5709-b145-64f9675744e4" href="#">Create A New Secret Key</a>
<h4 className="heading-7">Default Organization</h4>
<div>If you belong to multiple organisations, this settings controls which organization is used be default when making requests with the API keys above</div>
<div className="form-block-2 w-form">
<form data-name="Email Form 3" data-wf-element-id="98ec6b17-f48d-2c95-80fc-fbb7619bb713" data-wf-page-id="650305782bc61751e5765214" id="email-form-3" method="get" name="email-form-3"><select className="select-field w-select" data-name="organisation-choice" id="organisation-choice" name="organisation-choice">
<option value="">ibleducation.com</option>
</select></form>
<div className="w-form-done">
<div>Thank you! Your submission has been received!</div>
</div>
<div className="w-form-fail">
<div>Oops! Something went wrong while submitting the form.</div>
</div>
</div>
<div className="text-block-26">Note: You can also specify which organization to use for each API request</div>
</div>
</div>
</div>
)}

export default Integrationapikeymodalbox;